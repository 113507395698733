<template>
  <nav class="navigation" :class="$route.fullPath == '/PartyArticleListPage'?'partyArticleListPage':''">
    <div class="navigation_let">
      <a-icon class="unordered" type="unordered-list" @click="unorderedClick" />
      <div v-if="$route.fullPath !== '/PartyArticleListPage'">
          <img
            @click="pathContent"
            slot="cover"
            alt="example"
            src="@/assets/image/hb.png"
          />
          <img
            slot="cover"
            alt="example"
            src="@/assets/image/ov.png"
          />
        </div>
        
        <img
            v-else
            @click="pathContent"
            slot="cover"
            alt="example"
            src="@/assets/image/partyArticleListPage/ovn.png"
          />
    </div>
    <div class="navigation_right">
      <ul>
        <li>
          <span @click.stop="navigationClick(1)" :class="popconfirmType===1?'fk':''">工作与服务</span>
          <span class="line"></span>
        </li>
        <li>
          <span @click.stop="navigationClick(4)" :class="popconfirmType===4?'fk':''">资源与应用</span>
          <span class="line"></span>
        </li>
        
        <li>
          <span @click.stop="navigationClick(2)" :class="popconfirmType===2?'fk':''">客户与伙伴</span>
          <span class="line"></span>
        </li>
        <li><a href="http://www.cloudhb.cn/" target="_blank">云上国安</a> <span class="line"></span></li>
        <li>
          <span @click.stop="navigationClick(3)" :class="popconfirmType===3?'fk':''">关于我们</span>
        </li>
        <a-icon class="search" type="search" @click="searchClick" />
      </ul>
      <Unordered ref="unordered"></Unordered>
      <Search ref="search"></Search>
    </div>
    <div class="popoverMask" v-show="popconfirmType != 0" >
      <div class="header-Popover" @click.stop>
        <Popconfirm1 v-show="popconfirmType===1"></Popconfirm1>
        <Popconfirm4 v-show="popconfirmType===4"></Popconfirm4>
        <Popconfirm2 v-show="popconfirmType===2"></Popconfirm2>
        <Popconfirm3 v-show="popconfirmType===3"></Popconfirm3>
      </div>
    </div>
  </nav>
</template>

<script>
import Unordered from './components/Unordered.vue';
import Search from './components/standSearch.vue';
import Popconfirm1 from './components/popconfirm1.vue';
import Popconfirm4 from './components/popconfirm4.vue';
import Popconfirm2 from './components/popconfirm2.vue';
import Popconfirm3 from './components/popconfirm3.vue';

export default {
  name:'Navigation',
  components: {
    Unordered,
    Popconfirm1,
    Popconfirm4,
    Popconfirm2,
    Popconfirm3,
    Search
  },
  data(){
    return{
      popconfirmType: 0
    }
  },
  mounted() {
    document.body.addEventListener('click', this.handleBodyClick);
  },
  beforeUnmount() {
    document.body.removeEventListener('click', this.handleBodyClick);
  },
  created(){},
  methods:{
    handleBodyClick(event) {
      // 处理点击事件的逻辑
      this.popconfirmType = 0
    },
    navigationClick(t){
      this.popconfirmType = t
    },
    pathContent(){
      this.$router.push('/')
    },
    unorderedClick(){
      this.$refs.unordered.visible = true
    },
    searchClick(){
      this.$refs.search.visible = true
    },
  }
};
</script>
<style lang="less" scoped>
.navigation {
  /* 导航栏样式和布局的CSS规则 */
    display: flex;
    justify-content: space-between;
    align-items: center;
  .navigation_let{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .unordered{
      font-size: 25px;
      color: #fff;
      margin-right: 20px;
      cursor: pointer;
      animation: antd-pro-components-header-header-iconFlicker 1s infinite;
    }
    img{
      cursor: pointer;
    }

  }
  .navigation_right{
    font-size: 16px;
    color: #fff;
    a{
      text-decoration: none;
      color: #fff;
    }
    ul{
      list-style:none;    //去掉标签默认的左边符号
      display:flex;
      flex-wrap:wrap;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 0;
      .search{
        margin-left: 60px;
        font-size: 26px;
        cursor: pointer;
      }
    }
    li{
      list-style-type:none;    //去掉标签默认的左边符号
      text-align: center;
      padding: 0 20px;
      cursor: pointer;
      position: relative;
      >span{
        position: relative;
      }
      .fk:after {
        content: "";
        display: inline-block;
        width: 20px;
        height: 20px;
        background-color: #fff;
        position: absolute;
        left: 50%;
        bottom: -10px;
        transform: translate(-50%, 100%) rotate(45deg);
    }
      .line {
        right: 0;
        display:block;
        height: 18px;
        width: 1px;
        background-color: rgb(255, 255, 255);
        position: absolute;
        right: 0;
        top: 38px;
      }
    }
  }
}
.partyArticleListPage{
  .navigation_let{
    .unordered{
      color: rgb(255, 255, 102);
    }
  }
  .navigation_right{
    color: rgb(255, 255, 102);
    a{ 
      color: rgb(255, 255, 102);
    }
    li .line {
      background-color: rgb(255, 255, 102);
    }
  }
}
.popoverMask{
  position: fixed;
  top: 100px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  .header-Popover{
    padding: 30px;
    line-height: 1;
    position: fixed;
    background-color: #fff;
    top: 75px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 5px 5px rgba(0, 0, 0, .3);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}
@keyframes antd-pro-components-header-header-iconFlicker {
  0% {
    opacity: .2;
  }
  50% {
      opacity: 1;
  }
  100% {
      opacity: .2;
  }
}
</style>
