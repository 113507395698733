<template>
    <div class="popconfirm4">
        <div class="popconfirm4_content">
            <div class="title">资源与应用</div>
            <a-tabs :default-active-key="0" tab-position="left">
                <a-tab-pane v-for="(item, i) in dataLis" :key="i">
                    <span slot="tab"><span class="tabs_tab">{{ item.tab }}</span></span>
                    <div class="tabs_content" v-for="(item1, i1) in item.list" :key="i1">
                        <h1 @click="open(item1)">{{ item1.title }}</h1>
                        <p>{{ item1.content }}</p>
                    </div>
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>
</template>
<script>
export default {
    name:'popconfirm4',
    data () {
        return {
            dataLis:[{
                tab: '资源',
                list: [{
                    title: '国家、行业、地方标准资源库',
                    content: ''
                },{
                    title: '团体、企业标准资源库物品编码部',
                    content: ''
                },{
                    title: '术语定义资源库',
                    content: ''
                }]
            },{
                tab: '应用',
                list: [{
                    title: '智能标准制（修）订工具',
                    content: ''
                },{
                    title: '湖北省养老机构等级评定管理系统',
                    content: ''
                }]
            }]
        }
    },
    methods:{
        open(item){
            if (item.title === '智能标准制（修）订工具') {
                window.open('http://gear.hbsqi.com/', '_blank');
            }
        }
    }
};
</script>
<style  lang="less">
    .popconfirm4_content{
        padding: 30px 20px;
        .title{
            color: #333;
            font-size: 32px;
            padding: 0 0 18px;
            margin-bottom: 15px;
            border-bottom: 1px solid #ccc;
        }
        .ant-tabs-tabpane{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            max-height: 65vh;
            overflow-y: scroll;
            padding: 20px;
        }
        .tabs_tab{
            font-size: 20px;
        }
        .tabs_content{
            h1{
                font-size: 24px;
                font-weight: 400;
                font-stretch: normal;
                line-height: 22px;
                letter-spacing: 0;
                color: #06c;
                margin-bottom: 25px;
            }
            p{
                color: #666;
                line-height: 1.5;
                width: 650px;
            }
        }
    }
</style>
