/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
// 引入Ant Design Vue
import Antd from 'ant-design-vue';

// 引入Ant Design Vue的样式文件
import 'ant-design-vue/dist/antd.css';

Vue.config.productionTip = false


import router from '@/router/index'
// 将Ant Design Vue注册为Vue的插件
Vue.use(Antd);

router.beforeEach((to,from, next) => {
  if(to.meta.title){
    document.title = to.meta.title
  }
  next()
})

new Vue({
  router ,
  render: h => h(App),
}).$mount('#app')
