<template>
    <div class="standSearch">
        <a-drawer
            placement="top"
            height="calc(100vh)"
            :closable="false"
            :visible="visible"
        >
            <div class="standSearch_content">
                <div class="closeBtn" @click="onClose">
                    <span>关闭</span>
                    <img
                        slot="cover"
                        alt="example"
                        src="@/assets/image/close.png"
                    />
                </div>
                <div><a-icon class="search" type="search"/></div>
                <a-input class="searchInput" v-model="searchInput" placeholder="请输入搜索词" />
                <a-button type="primary" class="searchButton" @click="searchButton">站内检索</a-button>
            </div>
        </a-drawer>
    </div>
</template>
<script>
export default {
    name:'standSearch',
    data () {
        return {
            visible: false,
            searchInput: ''
        }
    },
    methods:{
        searchButton(){
            this.$router.push(`/ArticleListPage/${this.searchInput}`)
            this.onClose()
        },
        onClose(){
            this.visible = false
        }
    }
};
</script>
<style  lang="less">
    .standSearch_content{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .search{
            font-size: 80px;
            color: #06c;
        }
        .searchInput{
            border: none;
            border-bottom: 1px solid #999;
            background: transparent;
            width: 60%;
            font-size: 28px;
            line-height: 34px;
            letter-spacing: 1px;
            color: #999;
            display: block;
            margin: 180px auto 40px;
            outline: none;
            padding: 27px 5px;
            border-radius: 0;
        }
        .searchInput:focus{
            box-shadow: none;
            border-color: #40a9ff;
            border-right-width: 1px !important;
        }
        .searchInput:hover{
            border-color: #40a9ff;
            border-right-width: 1px !important;
        }
        .searchButton{
            width: 280px;
            height: 72px;
            background-color: #06c;
            border-radius: 6px;
            border: none;
            color: #fff;
            font-size: 36px;
            outline: none;
            cursor: pointer;
        }
    }
</style>
