import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 导入和配置路由组件
import index from '../page/index.vue';
import content from '../page/content.vue';
import ArticleListPage from '../page/ArticleListPage.vue';
import Association from '../page/Association.vue';
import AboutUs from '../page/AboutUs.vue';
import ArticleInfo from '../page/ArticleInfo.vue';
import LeaveMessageListPage from '../page/LeaveMessageListPage.vue';
import deanEmail from '../page/deanEmail.vue';
import PartyArticleListPage from '../page/PartyArticleListPage.vue';

const routes = new VueRouter({
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
        return savedPosition
        } else {
        return { x: 0, y: 0 }
        }
    },
    routes:[
        { 
            path: '/', 
            component: index,
            redirect: '/content',
            matched:{},
            children: [
                {
                    path:'content',
                    //也不使用懒加载，先在文件头部提前引入
                    component:content,
                    meta:{
                        title:'湖北省标准化与质量研究院'
                    }
                },{
                    // 文章列表
                    path:'ArticleListPage/:id?',
                    component:ArticleListPage,
                    meta:{
                        title:'文章列表'
                    }
                },{
                    // 部门介绍
                    path:'Association/:id?',
                    component:Association,
                    meta:{
                        title:'部门介绍'
                    }
                },{
                    // 机构概况
                    path:'AboutUs/:id?',
                    component:AboutUs,
                    meta:{
                        title:'机构概况'
                    }
                },{
                    // 文章详情
                    path:'ArticleInfo/:id?',
                    component:ArticleInfo,
                    meta:{
                        title:'文章详情'
                    }
                },{
                    // 留言
                    path:'LeaveMessageListPage',
                    component:LeaveMessageListPage,
                    meta:{
                        title:'湖北省标准化与质量研究院'
                    }
                },{
                    // 写信
                    path:'deanEmail',
                    component:deanEmail,
                    meta:{
                        title:'湖北省标准化与质量研究院'
                    }
                },{
                    // 建党
                    path:'PartyArticleListPage',
                    component:PartyArticleListPage,
                    meta:{
                        title:'建党'
                    }
                },{
                    path:'/ggfwbz',
                    redirect: '/ggfwbz/file.html'

                }

            ]
        }
    ]
})

export default routes