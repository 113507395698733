import { render, staticRenderFns } from "./standSearch.vue?vue&type=template&id=60f8396e"
import script from "./standSearch.vue?vue&type=script&lang=js"
export * from "./standSearch.vue?vue&type=script&lang=js"
import style0 from "./standSearch.vue?vue&type=style&index=0&id=60f8396e&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports