<template>
    <div class="partyArticleListPage">
        <div class="background"></div>
        <div class="pageFirst page1">
            <div class="pageImg"></div>
            <div class="firstList">
                <div class="header">
                    <h1>{{ page1Obj.name }}</h1>
                    <a-button class="bt" @click="pageOpen(page1Obj,1)">更 多</a-button>
                </div>
                <div>
                    <div class="articleItem" v-for="(item, i) in page1Obj.list" :key="i" @click="pageOpen(item,2)">
                        <p>{{ item.title }}</p>
                        <div>{{ item.createTime }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pageFirst page2">
            <div class="LoopContentLeft">
                <h1>{{ page2Obj.name }}</h1>
                <a-button class="bt" @click="pageOpen(page2Obj, 1)">查看全部</a-button>
            </div>
            <div class="LoopContentRight">
                <a-carousel arrows :dots="false" :slides-to-show="3" :slides-to-scroll="1">
                    <div
                        slot="prevArrow"
                        slot-scope="props"
                        class="custom-slick-arrow"
                        style="left: 10px;zIndex: 1"
                        >
                        <a-icon type="left-circle" />
                    </div>
                    <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 10px">
                        <a-icon type="right-circle" />
                    </div>
                    <div class="carousel_view" v-for="(item, i) in page2Obj.list" :key="i" @click="pageOpen(item,2)">
                        <div class="LoopItemImg" :style="{backgroundImage:`url(${item.bji})`}"></div>
                        <span>{{ item.title }}</span>
                    </div>
                </a-carousel>
            </div>
        </div>
        <div class="pageFirst page3">
            <div class="firstList">
                <div class="header">
                    <h1>{{ page3Obj.name }}</h1>
                    <a-button class="bt" @click="pageOpen(page3Obj, 1)">更 多</a-button>
                </div>
                <div>
                    <div class="articleItem" v-for="(item, i) in page3Obj.list" :key="i" @click="pageOpen(item,2)">
                        <p>{{ item.title }}</p>
                        <div>{{ item.createTime }}</div>
                    </div>
                </div>
            </div>
            <div class="firstList">
                <div class="header">
                    <h1>{{ page4Obj.name }}</h1>
                    <a-button class="bt" @click="pageOpen(page4Obj, 1)">更 多</a-button>
                </div>
                <div>
                    <div class="articleItem" v-for="(item, i) in page4Obj.list" :key="i" @click="pageOpen(item,2)">
                        <p>{{ item.title }}</p>
                        <div>{{ item.createTime }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getChildItem } from "@/api/index";
import { getNewsList } from "@/api/ArticleListPage";
import { getImgRegex, objectMerge } from "@/utils/index";
export default {
    data() {
        return {
            page1Obj:{},
            page2Obj:{},
            page3Obj:{},
            page4Obj:{},
        };
    },
    created(){
        this.getChildItem()
    },
    methods:{
        getChildItem(){
            getChildItem('7F170C29B59B7E01E0532D0B000A8E43').then(res => {
                res.forEach((item, i) => {
                    this.getNewsList(item,i)
                })
            })
        },
        getNewsList(obj, index){
            getNewsList(obj.id).then(res => {
                obj.list = res.data
                if(index == 0){
                    this.page1Obj = obj
                }else if( index == 1){
                    obj.list.forEach(item =>{
                        item.bji = getImgRegex(item.content)
                    })
                    this.page2Obj = obj
                }else if(index== 2){
                    this.page3Obj = obj
                }else if(index == 3){
                    this.page4Obj = obj
                }
            })
        },
        pageOpen(item, t){
            if(t === 1){
                window.open(`${window.location.origin}/#/ArticleListPage/${item.id}`, '_blank')
            }else{
                window.open(`${window.location.origin}/#/ArticleInfo/${item.id}`, '_blank')
            }
        },
    }
}
</script>

<style lang="less" scoped>
.partyArticleListPage{
    .background{
        width: 100%;
        vertical-align: top;
        background-size: cover;
        background-position: top;
        height: 800px;
        background-image: url('../assets/image/partyArticleListPage/partLoogImg.png');
    }
    .pageFirst{
        background-color: #fff;
        padding: 89px calc(50% - 600px);
        .firstList{
            flex: 1 1;
            .header{
                display: flex;
                justify-content: space-between;
                border-bottom: 3px solid #09f;
                border-bottom-color: rgb(204, 0, 0);
                >h1{
                    font-size: 24px;
                    color: #999;
                }
                .bt{
                    border-radius: 100px;
                    padding: 0 17px;
                    height: 24px;
                    border-color: rgb(204, 0, 0);
                    color: rgb(204, 0, 0);
                }
            }
            .articleItem{
                margin-top: 20px;
                >p{
                    color: rgb(204, 0, 0);
                    font-size: 16px;
                    cursor: pointer;
                }
                .div{
                    font-size: 14px;
                    color: #999;
                }
            }
        }
    }
    .page1{
        display: flex;
        justify-content: stretch;
        .pageImg{
            width: 567px;
            height: 372px;
            margin-right: 56px;
            background-size: cover;
            background-position: 50%;
            background-image: url('../assets/image/partyArticleListPage/63161684381500041.png');
        }
    }
    .page2{
        height: 240px;
        background-color: #f1f1f1;
        display: flex;
        align-items: center;
        .LoopContentLeft{
            display: inline-block;
            >h1{
                font-size: 36px;
                margin-bottom: 40px;
                color: #c00;
                border-bottom: 1px solid #c00;
            }
            .bt{
                border-radius: 100px;
                color: #fff;
                padding: 9px 20px;
                line-height: 1;
                transition: all .3s;
                font-weight: 700;
                border: 1px solid #c00;
                background-color: #c00;
            }
        }
        .LoopContentRight{
            margin-left: 77px;
            display: inline-block;
            flex: 1 1;
            width: 100px;
            .custom-slick-arrow {
                width: 32px;
                height: 32px;
                font-size: 35px;
                color: #ccc;
            }
            .custom-slick-arrow:first-child {
                left: -30px;
            }
            .custom-slick-arrow:last-child {
                right: -30px;
            }
            .custom-slick-arrow:before {
                display: none;
            }
            .custom-slick-arrow:hover {
                opacity: 0.5;
            }
            .carousel_view{
                cursor: pointer;
                width: 100%;
                display: inline-block;
                position: relative;
                padding-right: 20px;
                .LoopItemImg{
                    background-position: 50%;
                    background-size: cover;
                    height: 180px;
                }
                >span{
                    font-weight: 700;
                    font-size: 16px;
                    color: #fff;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    word-break: keep-all;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    background: linear-gradient(0deg, rgba(0, 0, 0, .6), transparent);
                    padding: 15px;
                    margin: 0 15px;
                }
            }
        }
    }
    .page3{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        background-color: #fff;
        padding: 89px calc(50% - 600px);
        .firstList{
            width: 540px;
            flex: 0 0 540px;
        }
    }

}    
</style>