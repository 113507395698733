import request from "@/service/index";

export function getNewsList (params){
    return request({
      url: `/news/list/1/${params}.json`,
      method: 'get',
    })
}
export function getNews (params){
  return request({
    url: `/news/news/${params}.json`,
    method: 'get',
  })
}
