import request from "@/service/index";

// 100.json
export function get100 (params){
  return request({
    url: '/100.json',
    method: 'get',
    params
  })
}

// 10.json
export function get10 (params){
  return request({
    url: '/10.json',
    method: 'get',
    params
  })
}

export function get5 (){
  return request({
    url: '/officialWebBoard/getList/1/5.json',
    method: 'get'
  })
}

export function add (data){
  return request({
    url: '/officialWebBoard/add',
    method: 'POST',
    data
  })
}

export function deanmailboxDdd (data){
  return request({
    url: '/services/indexManage/deanmailbox/add',
    method: 'POST',
    data
  })
}

export function getChildItem (params){
  return request({
    baseURL: `/static/item/${params}.json`,
    method: 'get',
  })
}
