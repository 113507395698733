<template>
    <div class="popconfirm1">
        <div class="popconfirm1_content">
            <div class="title">工作与服务</div>
            <a-tabs :default-active-key="0" tab-position="left">
                <a-tab-pane v-for="(item, i) in dataLis" :key="i">
                    <span slot="tab"><span class="tabs_tab">{{ item.tab }}</span></span>
                    <div class="tabs_content" v-for="(item1, i1) in item.list" :key="i1">
                        <h1>{{ item1.title }}</h1>
                        <p>{{ item1.content }}</p>
                    </div>
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>
</template>
<script>
export default {
    name:'popconfirm1',
    data () {
        return {
            dataLis:[{
                tab: '工作',
                list: [{
                    title: '事业发展规划部',
                    content: '省标质院事业发展规划部（以下简称“事业部”）是负责、协调全院业务工作开展、管理收入类经济合同工作开展的业务部门。负责市场开发、拓展、各业务部门商务信息收集整理、经济类收入合同的日常管理，合同登记'
                },{
                    title: '物品编码部',
                    content: '从事物品编码管理、技术研究与商品条码系统成员服务的专职部门'
                },{
                    title: '标准文献馆',
                    content: '主要负责全省标准文献馆藏资源规划与建设、全省标准技术审评与实施效果评价、全省标准化管理工作技术支撑与数据支持、 相关领域标准化技术解决方案与标准化技术咨询服务'
                },{
                    title: '高新技术与产业标准化研究',
                    content: '专业从事高新技术领域研究的标准化研究所'
                },{
                    title: '现代服务业与公共管理标准化研究所',
                    content: '从事现代服务业和公共管理标准化研究及技术服务的专职部门'
                },{
                    title: '国际标准化研究所',
                    content: '从事技术性贸易措施研究、WTO 通报咨询、国际标准化合作与研究、检验检测研究和湖北省标准学会的专职部门'
                },{
                    title: '质量发展与质量安全研究所',
                    content: '从事质量发展和质量安全研究及技术服务的专职部门'
                },{
                    title: '现代物流标准化研究所',
                    content: '从事商贸、物流、交通行业标准化研究及技术服务的专职部门 负责省物标委秘书处的日常工作运转'
                },{
                    title: '资源与环境标准化研究所',
                    content: '资源与环境标准化研究及技术服务的专职部门'
                }]
            },{
                tab: '服务',
                list: [{
                    title: '标准服务',
                    content: '致力于“标准文献服务、各类标准制修订、标准化试点示范咨询与服务、国际标准化活动与服务”等，为标准化实践提供最佳的解决方案'
                },{
                    title: '标准化及应用',
                    content: '致力于“标准化咨询、标准化体系规划、标准实施、标准化政策研究、码应用”等服务，为标准化及应用提供最佳的解决方案'
                },{
                    title: '检测服务',
                    content: '致力于“检测咨询、检测信息平台、检测宏观研究、检测实验室建设”等服务，为检验检测实践提供最佳的解决方案'
                },{
                    title: '认证服务',
                    content: '致力于“体系（服务、信息安全）认证、质量管理体系服务、标准相关的评审认证咨询培训”等服务，为认证服务提供最佳的解决方案'
                },{
                    title: '质量服务',
                    content: '致力于“质量政策咨询、企业质量提升、质量技术服务、缺陷管理、质量强省示范县、质量奖、品牌建设服务”等服务，为质量提升提供最佳的解决方案'
                },{
                    title: '咨询服务',
                    content: '致力于“管理政策咨询、培训”等服务，为咨询服务实践提供最佳的解决方案'
                }]
            }]
        }
    },
    methods:{
    }
};
</script>
<style  lang="less">
    .popconfirm1_content{
        padding: 30px 20px;
        .title{
            color: #333;
            font-size: 32px;
            padding: 0 0 18px;
            margin-bottom: 15px;
            border-bottom: 1px solid #ccc;
        }
        .ant-tabs-tabpane{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            max-height: 65vh;
            overflow-y: scroll;
            padding: 20px;
        }
        .tabs_tab{
            font-size: 20px;
        }
        .tabs_content{
            h1{
                font-size: 24px;
                font-weight: 400;
                font-stretch: normal;
                line-height: 22px;
                letter-spacing: 0;
                color: #06c;
            }
            p{
                color: #666;
                line-height: 1.5;
                width: 650px;
            }
        }
    }
</style>
