<template>
    <footer class="footer">
        <!-- 页脚内容 -->
        <p class="p"> © 2018-2024 湖北省标准化与质量研究院版权所有</p>
        <hr>
        <div class="footer-content">
            <!-- 其他页脚内容 -->
            <div>
                <a href="https://weibo.com/HubeiStandardization?is_hot=1" target="_blank">
                    <a-icon class="icon" type="weibo" />
                </a>
                <a-popover trigger="click">
                    <template slot="content">
                        <img
                            slot="cover"
                            alt="example"
                            src="@/assets/image/footer/footer1.jpg"
                        />
                    </template>
                    <a-icon class="icon" type="wechat" />
                </a-popover>
            </div>
            <div class="right">
                <a href="http://beian.miit.gov.cn/" target="_blank">鄂ICP备07002370号-18</a>
                <img
                    class="img"
                    slot="cover"
                    alt="example"
                    src="@/assets/image/footer/footer2.png"
                />
                <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42010602003573" target="_blank">鄂公网安备 42010602003573号</a>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
    name:'Footer'
};
</script>
<style  lang="less" scoped>
.footer {
    padding: 40px 0;
    margin: 0 auto;
    text-align: center;
    max-width: 1200px;
    position: relative;
    .p{
        color: unset;
        text-align: right;
        font-size: 14px;
    }
    hr{
        border: none;
        border-top: 1px solid #f5f5f5;
        border-bottom: 1px solid #d0d0d0;
        margin: 16px -50px;
    }
    .footer-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .icon{
            cursor: pointer;
            font-size: 30px;
            margin-right: 20px;
            color: rgb(0, 102, 204);
   
        }
        .right{
            vertical-align: middle;
            color: rgb(0, 102, 204);
            display: flex;
            align-items: center;
            justify-content: center;
            .img{
                margin: 0 10px;
            }
        }
    }
}

</style>
