/* eslint-disable */
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="less">
#app {
  min-width: 1200px;
  overflow: hidden;
  background-color: #fff;
}
.ant-drawer-wrapper-body{
  min-width: 1000px;
  background-color: #000;
}
.ant-drawer-body{
  min-width: 1000px;
  height: 100%;
  .closeBtn{
    position: fixed;
    right: 60px;
    top: 60px;
    display: inline-flex;
    cursor: pointer;
    align-items: center;
    z-index: 1000;
    span{
        margin-right: 15px;
        opacity: 0;
        transform: translateX(100%);
        transition: all .3s;
        color: #fff;
        font-size: 18px;
        
    }
    img{
        transition: all .8s;
    }
  }
  .closeBtn:hover span {
      transform: none;
      opacity: 1;
  }
  .closeBtn:hover  img {
      transform: rotate(1turn);
  }
}
</style>
