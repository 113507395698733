<template>
    <div class="deanEmail">
        <h1>我要写信</h1>
        <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">
            <a-form-item label="姓名">
                <a-input
                    v-decorator="['name', { rules: [
                        { required: true, message: '请输入姓名' }
                    ]}]"
                />
            </a-form-item>
            <a-form-item label="手机号码">
                <a-input
                    v-decorator="['phone',{ rules: [
                        {
                            pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
                            message: '请输入正确手机号'
                        },
                        { required: true, message: '请输入手机号码'},
                    ]}]"
                />
            </a-form-item>
            <a-form-item label="联系邮箱">
                <a-input
                    v-decorator="['mail',{ rules: [
                        { type:'email', message: '请输入正确的格式' },
                        { required: true, message: '请输入联系邮箱' }
                    ]}]"
                />
            </a-form-item>
            <a-form-item label="留言标题">
                <a-input
                    v-decorator="['msgTitle', 
                        { rules: [{ required: true, message: '请输入留言标题' }] }
                    ]"
                />
            </a-form-item>
            <a-form-item label="留言内容">
                <a-textarea 
                :autoSize="{'minRows': 4, 'maxRows': 4}"
                v-decorator="['msgContent',{rules: [{ required: true, message: '请输入留言内容' }]}]"
                :rows="4" />
            </a-form-item>
            <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
                <a-button type="primary"  class="submit" html-type="submit">
                    提 交
                </a-button>
                <a-button @click="btnCancel">重 置</a-button>
            </a-form-item>
        </a-form>
    </div>
</template>

<script>
import { deanmailboxDdd } from "@/api/index";
export default {
    data() {
        return {
            form: this.$form.createForm(this, { name: 'coordinated' }),
        };
    },
    methods:{
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if (!err) {
                    deanmailboxDdd(values).then(res=>{

                    })
                }
            });
        },
        btnCancel(){
          this.form.resetFields()
        },
    }
}
</script>

<style lang="less" scoped>
.deanEmail{
    background-color: #fff;
    margin-top: 90px;
    padding: 50px calc(50% - 552.5px);
    >h1{
        font-size: 30px;
        line-height: inherit;
        color: #06c;
        padding-bottom: 15px;
        border-bottom: 1px solid #06c;
    }
    .submit{
        margin-right: 30px;
    }
}    
</style>