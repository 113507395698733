<template>
    <div class="leaveMessageListPage">
        <h1>留言公开</h1>
        <div>
            <a-list :loading="listLoading" item-layout="vertical" size="large" :pagination="pagination" :data-source="listData">
                <a-list-item class="renderItem" slot="renderItem" key="item.title" slot-scope="item">
                    <p>群众留言：{{ item.message }}</p>
                    <p class="page-time">{{ item.messageDate }}</p>
                    <p>官方回复：{{ item.reply }}</p>
                    <p class="page-bt" @click="leaveMessage(item)">查看详情</p>
                </a-list-item>
            </a-list>
        </div>
        <div class="page-Leave">
            <a-textarea 
            placeholder="留言字数限制200字"
            @change="onChange"
            :autoSize="{'minRows': 6, 'maxRows': 6}"
            :maxLength="200"
            v-model="from.message"
            :rows="4" />
            <a-button type="primary" class="btn" @click="submit" :disabled="disabled">提交留言</a-button>
        </div>
        
        <a-modal class="leaveMessageModal" v-model="visible" title="留言详情" :footer="null" width="500px">
           <div class="renderItem">
                <div>群众留言：
                    <div>
                        <span>{{ leaveMessageObj.message }}</span>
                        <span class="span">{{  leaveMessageObj.messageDate }}</span>
                    </div>
                </div>
                <div>
                    官方回复：
                    <div>
                        <span>{{ leaveMessageObj.reply }}</span>
                        <span class="span">{{  leaveMessageObj.replyDate }}</span>
                    </div>
                </div>
           </div>
        </a-modal>
    </div>
</template>

<script>
import { get5, add } from "@/api/index";
import { parseTime } from "@/utils/index";

export default {
    data() {
        return {
            listData: [],
            listLoading: false,
            pagination: {
                pageSize: 5,
            },
            disabled:true,
            from:{
                message: '',
                messageDate: ''
            },
            visible: false,
            leaveMessageObj:{}
        };
    },
    created(){
        this.get5()
    },
    methods:{
        onChange(){
            if(this.from.message.length > 0){
                this.disabled = false
            }else{
                this.disabled = true
            }
        },
        get5(){
            this.listLoading = true
            get5().then(res => {
                this.listLoading = false
                this.listData = res.data
            })
        },
        submit(){
            this.from.messageDate = parseTime(new Date())
            add(this.from).then(res => {
                
            })
        },
        leaveMessage(item){
            this.visible = true
            this.leaveMessageObj = item
        }
    }
}
</script>

<style lang="less" scoped>
.leaveMessageListPage{
    margin-top: 90px;
    padding: 50px calc(50% - 551.5px) 90px;
    background: #fff;
    >h1{
        font-size: 30px;
        color: #0da1ff;
        border-bottom: 1px solid #0da1ff;
        font-weight: 400;
        padding-bottom: 10px;
        margin-bottom: 35px;
        display: flex;
        justify-content: space-between;
    }
    .renderItem{
        padding: 30px 0;
        border-bottom: 1px solid #e6e6e6;
        cursor: pointer;
        position: relative;
        p{
            font-size: 16px;
            color: #666;
        }
        .page-time{
            position: absolute;
            top: 54px;
            right: 0;
        }
        .page-bt{
            position: absolute;
            top: 94px;
            right: 0;
            color: #0da1ff;
        }
    }
    .page-Leave{
        margin-top: 20px;
        .btn{
            margin-top: 10px;
        }
    }
}    
</style>
<style>
    .leaveMessageModal .renderItem div{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 10px;
    }
    .leaveMessageModal .renderItem >div >div{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
    .leaveMessageModal .renderItem >div >div .span{
        font-size: 10px;
        color: #aaa;
    }
</style>