<template>
    <footer class="contact" :class="$route.fullPath == '/PartyArticleListPage'?'partyArticleListPage':''">
        <div class="footer_contact">
            <div class="footer_contact_content">
                <div>
                    <img
                        slot="cover"
                        alt="example"
                        src="@/assets/image/footer/contact1.png"
                    />
                    <h2>电　话</h2>
                    <span>电话：027-88218365</span>
                    <span>传真：027-88218365</span>
                    <span>院纪检监察举报电话：027-88226012</span>
                </div>
                <div>
                    <div>
                        <div>
                            <img
                                slot="cover"
                                alt="example"
                                src="@/assets/image/footer/contact2.png"
                            />
                            <h2>工作地点</h2>
                            <span>湖北省武汉市武昌区公平路6号</span>
                            <span>武汉地铁轨道交通2号线、7号线</span>
                            <span>螃蟹岬地铁站A1出口步行600米</span>
                        </div>
                        <a-button @click="path('/LeaveMessageListPage')">在线咨询</a-button>
                    </div>
                </div>
                <div>
                    <div>
                        <div>
                            <img
                                slot="cover"
                                alt="example"
                                src="@/assets/image/footer/contact3.png"
                            />
                            <h2>邮　件</h2>
                            <span>工作信箱：bgs@hbsqi.com</span>
                        </div>
                        <a-button @click="path('/deanEmail')">院长信箱</a-button>
                    </div>
                </div>
            </div>
            <p>为您提供满意的服务，是我们工作的动力<br>您可以通过电话、邮件或莅临我们的工作地点与我们沟通</p>
        </div>
    </footer>
</template>
<script>
export default {
    name:'Contact',
    methods:{
        path(url){
            this.$router.push(url)
        },
    }
};
</script>
<style  lang="less" scoped>
.contact { 
    text-align: center;
    .footer_contact{
        background-color: #06c;
        color: #fff;
        text-align: center;
        padding: 80px calc(50% - 600px) 32px;
        position: relative;
        margin: 0 -50px;
        .footer_contact_content{
            display: flex;
            justify-content: space-between;
            padding: 0 30px;
            height: 400px;
            margin-bottom: 20px;
            >div{
                display: inline-block;
                text-align: center;
                vertical-align: top;
                width: calc(33.33333% - 100px);
                >div{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100%;
                }
                img{
                    margin-bottom: 40px;
                }
                h2{
                    color: #fff;
                    font-size: 28px;
                    margin-bottom: 30px;
                    font-weight: 400;
                }
                span{
                    margin-bottom: 12px;
                    word-wrap: break-word;
                    word-break: break-all;
                    display: block;
                    line-height: 1.5;
                    font-size: 16px;
                }
                .ant-btn{
                    display: block;
                    background: transparent;
                    color: #fff;
                    border-color: #fff;
                    border-radius: 100px;
                    margin: 40px auto 0;
                    width: 148px;
                    height: 42px;
                    z-index: 1;
                }
                .ant-btn:active{
                    outline: 0;
                    box-shadow: none;
                }
                .ant-btn:hover{
                    background-color: #fff;
                    color: #0da1ff;
                }
            }
        }
    }
    .footer_contact:after{
        content: "";
        background-image: url(@/assets/image/footer/contact_bj.png);
        background-repeat: no-repeat;
        background-position: top;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}
.partyArticleListPage{
    .footer_contact{
        background-color: rgb(204, 0, 0);
    }
}

</style>
