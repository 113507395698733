var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"deanEmail"},[_c('h1',[_vm._v("我要写信")]),_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 12 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', { rules: [
                    { required: true, message: '请输入姓名' }
                ]}]),expression:"['name', { rules: [\n                    { required: true, message: '请输入姓名' }\n                ]}]"}]})],1),_c('a-form-item',{attrs:{"label":"手机号码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['phone',{ rules: [
                    {
                        pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
                        message: '请输入正确手机号'
                    },
                    { required: true, message: '请输入手机号码'},
                ]}]),expression:"['phone',{ rules: [\n                    {\n                        pattern: /^1(3\\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\\d|9[0-35-9])\\d{8}$/,\n                        message: '请输入正确手机号'\n                    },\n                    { required: true, message: '请输入手机号码'},\n                ]}]"}]})],1),_c('a-form-item',{attrs:{"label":"联系邮箱"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['mail',{ rules: [
                    { type:'email', message: '请输入正确的格式' },
                    { required: true, message: '请输入联系邮箱' }
                ]}]),expression:"['mail',{ rules: [\n                    { type:'email', message: '请输入正确的格式' },\n                    { required: true, message: '请输入联系邮箱' }\n                ]}]"}]})],1),_c('a-form-item',{attrs:{"label":"留言标题"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['msgTitle', 
                    { rules: [{ required: true, message: '请输入留言标题' }] }
                ]),expression:"['msgTitle', \n                    { rules: [{ required: true, message: '请输入留言标题' }] }\n                ]"}]})],1),_c('a-form-item',{attrs:{"label":"留言内容"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['msgContent',{rules: [{ required: true, message: '请输入留言内容' }]}]),expression:"['msgContent',{rules: [{ required: true, message: '请输入留言内容' }]}]"}],attrs:{"autoSize":{'minRows': 4, 'maxRows': 4},"rows":4}})],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 12, offset: 5 }}},[_c('a-button',{staticClass:"submit",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 提 交 ")]),_c('a-button',{on:{"click":_vm.btnCancel}},[_vm._v("重 置")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }