import request from "@/service/index";

// getShowAll.json
export function getShowAll (params){
  return request({
    baseURL: '/static/item/showAll.json',
    method: 'get',
    params
  })
}

export function getNewsList (params){
  return request({
    url: `/news/list/${params}.json`,
    method: 'get',
  })
}