<template>
    <div class="articleListPage">
        <hr />
        <div class="spanTotal">为您找到相关结果{{total}}个</div>
        <div>
            <a-list :loading="listLoading" item-layout="vertical" size="large" :pagination="pagination" :data-source="listData">
                <a-list-item class="renderItem" slot="renderItem" key="item.title" slot-scope="item">
                    <div @click="open(item)">
                        <h3>{{ item.title }}</h3>
                        <h5>{{ item.newCreateTime }}</h5>
                        <p>{{ item.mewContent }}...</p>
                    </div>
                </a-list-item>
            </a-list>
        </div>
    </div>
</template>

<script>
import { getNewsList } from "@/api/ArticleListPage";
import { getHtml, setDateTime } from "@/utils/index";

export default {
    data() {
        return {
            id: '',
            total: 0,
            listData: [],
            pagination: {
                pageSize: 10,
            },
            listLoading: false
        };
    },
    created(){
        this.id = this.$route.params.id?this.$route.params.id:''
        this.getNewsList()
    },
    methods:{
        getNewsList(){
            this.listLoading = true
            getNewsList(this.id).then(res => {
                this.listLoading = false
                this.total = res.length
                res.data.forEach(element => {
                    element.newCreateTime = setDateTime(element.createTime)
                    element.mewContent = element.content?getHtml(element.content):''
                });
                this.listData = res.data
            })
        },
        open(row){
            window.open(`${window.location.origin}/#/ArticleInfo/${row.id}`, '_blank');
        }
    }
}
</script>

<style lang="less" scoped>
.articleListPage{
    margin-top: 90px;
    background-color: #fff;
    padding: 50px calc(50% - 600px);
    >hr {
        background-color: #e6e6e6;
        height: 1px;
        margin: 45px 0;
        border: none;
    }
    .spanTotal{
        font-size: 16px;
        letter-spacing: 0;
        color: #d5d5d5;
        margin-bottom: 10px;
    }
    .renderItem{
        h3{
            font-size: 18px;
            font-weight: 400;
            color: #1890ff;
            cursor: pointer;
            margin-bottom: 26px;
        }
        h5{
            font-size: 16px;
            font-weight: 400;
            color: #ccc;
            float: right;
        }
        p{
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #666;
        }
    }
}    
</style>