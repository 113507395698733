<template>
    <div class="popconfirm3">
        <div class="popconfirm3_content">
            <div class="title">关于我们</div>
            <div class="popconfirm3_AboutUs">
                <div class="popconfirm3_AboutUsL">
                    <img slot="cover" alt="example" src="@/assets/image/popconfirm3/popconfirm3_1.png"/>
                </div>
                <div class="popconfirm3_AboutUsR">
                    <div class="popconfirm3_AboutUsR-Item" v-for="(item, i) in dataLis" :key="i">
                        <img slot="cover" alt="example" :src="item.src"/>
                        <h1>{{ item.title }}</h1>
                        <a target="_blank" @click="open(item1)" v-for="(item1, i1) in item.list" :key="i1">{{ item1.content }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'Unordered',
    data () {
        return {
            dataLis:[{
                src: require('@/assets/image/popconfirm3/popconfirm3_2.png'),
                title: '机构概况',
                list: [{
                    id: 1,
                    path: 'AboutUs',
                    content: '发展历程'
                },{
                    id: 2,
                    path: 'AboutUs',
                    content: '领导介绍'
                },{
                    id: 3,
                    path: 'AboutUs',
                    content: '获奖荣誉'
                },{
                    id: 4,
                    path: 'AboutUs',
                    content: '机构文化'
                }]
            },{
                src: require('@/assets/image/popconfirm3/popconfirm3_3.png'),
                title: '党建工作',
                list: [{
                    content: '党建动态',
                    path: 'ArticleListPage',
                    id: '8510AB524A8B2A22E0533A0B000A679B'
                },{
                    content: '政策文件',
                    path: 'ArticleListPage',
                    id: '7F17180673D4043EE0532D0B000A35DE'
                },{
                    content: '党旗下的标准人',
                    path: 'ArticleListPage',
                    id: '8510AB524A8C2A22E0533A0B000A679B'
                }]
            },{
                src: require('@/assets/image/popconfirm3/popconfirm3_4.png'),
                title: '资讯中心',
                list: [{
                    content: '信息公开',
                    path: 'ArticleListPage',
                    id: '8303F9CAB90F443CE0533A0B000AC026'
                },{
                    content: '工作动态',
                    path: 'ArticleListPage',
                    id: '7F170C29B59A7E01E0532D0B000A8E43'
                }]
            },{
                src: require('@/assets/image/popconfirm3/popconfirm3_5.png'),
                title: '联络我们',
                list: [{
                    content: '工作信箱：bgs@hbsqi.com'
                }]
            }]
        }
    },
    methods:{
        open(item){
            if (item.path === 'AboutUs') {
                window.open(`${window.location.origin}/#/${item.path}/${item.id}`, '_blank');
            }else{
                window.open(`${window.location.origin}/#/${item.path}/${item.id}`, '_blank');
            }
        }
    }
};
</script>
<style  lang="less">
    .popconfirm3_content{
        padding: 30px 20px;
        .title{
            color: #333;
            font-size: 32px;
            padding: 0 0 18px;
            margin-bottom: 15px;
            border-bottom: 1px solid #ccc;
        }
        .popconfirm3_AboutUs{
            display: flex;
            .popconfirm3_AboutUsL{
                margin-right: 30px;
                max-height: 65vh;
                background-position: 50%;
                background-size: contain;
            }
            .popconfirm3_AboutUsR{
                display: flex;
                .popconfirm3_AboutUsR-Item{
                    display: flex;
                    flex-direction: column;
                    img{
                        margin-bottom: 23px;
                        width: fit-content;
                    }
                    h1{
                        font-size: 24px;
                        color: #06c;
                        font-weight: 400;
                    }
                    a{
                        font-size: 16px;
                        color: #666;
                        line-height: normal;
                        margin-bottom: 6px;
                    }
                    a:hover {
                        color: #06c;
                        text-decoration: underline;
                    }
                }
                .popconfirm3_AboutUsR-Item:not(:last-child){
                    margin-right: 30px;
                }
            }
        }
    }
</style>
