import axios from "axios";

console.log();
const instance = axios.create({
  baseURL: process.env.NODE_ENV === 'development'?'/api':'',
})

instance.interceptors.response.use(response=>{
  if(response.status === 200){
    if(response.data.code && response.data.code === '10001'){
      return response.data.data
    }else{
      return {data:[]}
    }
  }else{
    return Promise.reject(new Error(response.data.msg || 'Error'))
  }
})

export default instance