<template>
    <div class="popconfirm2">
        <div class="popconfirm2_content">
            <div class="title">客户与伙伴</div>
            <a-tabs :default-active-key="0" tab-position="left">
                <a-tab-pane v-for="(item, i) in dataLis" :key="i">
                    <span slot="tab"><span class="tabs_tab">{{ item.tab }}</span></span>
                    <div class="tabs_view">
                        <div class="tabs_content" v-for="(item1, i1) in item.list" :key="i1">
                            <img
                                class="clientItem"
                                slot="cover"
                                alt="example"
                                :src="item1"
                            />
                        </div>
                    </div>
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>
</template>
<script>
export default {
    name:'Unordered',
    data () {
        return {
            dataLis:[{
                tab: '客户',
                list: [
                    require('@/assets/image/popconfirm2/popconfirm2_kh1.png'),
                    require('@/assets/image/popconfirm2/popconfirm2_kh2.png'),
                    require('@/assets/image/popconfirm2/popconfirm2_kh3.png'),
                    require('@/assets/image/popconfirm2/popconfirm2_kh4.png'),
                    require('@/assets/image/popconfirm2/popconfirm2_kh5.png'),
                    require('@/assets/image/popconfirm2/popconfirm2_kh6.png'),
                    require('@/assets/image/popconfirm2/popconfirm2_kh7.png'),
                    require('@/assets/image/popconfirm2/popconfirm2_kh8.png'),
                    require('@/assets/image/popconfirm2/popconfirm2_kh9.png'),
                    require('@/assets/image/popconfirm2/popconfirm2_kh10.png'),
                    require('@/assets/image/popconfirm2/popconfirm2_kh11.png'),
                    require('@/assets/image/popconfirm2/popconfirm2_kh12.png'),
                ]
            },{
                tab: '伙伴',
                list: [
                    require('@/assets/image/popconfirm2/popconfirm2_hb1.png'),
                    require('@/assets/image/popconfirm2/popconfirm2_hb2.png'),
                    require('@/assets/image/popconfirm2/popconfirm2_hb3.png'),
                    require('@/assets/image/popconfirm2/popconfirm2_hb4.png'),
                    require('@/assets/image/popconfirm2/popconfirm2_hb5.png'),
                    require('@/assets/image/popconfirm2/popconfirm2_hb6.png'),
                    require('@/assets/image/popconfirm2/popconfirm2_hb7.png'),
                    require('@/assets/image/popconfirm2/popconfirm2_hb9.png'),
                    require('@/assets/image/popconfirm2/popconfirm2_hb8.png'),
                    require('@/assets/image/popconfirm2/popconfirm2_kh12.png'),
                    require('@/assets/image/popconfirm2/popconfirm2_hb10.png'),
                    require('@/assets/image/popconfirm2/popconfirm2_hb11.png'),
                ]
            }]
        }
    },
    methods:{
    }
};
</script>
<style  lang="less">
    .popconfirm2_content{
        padding: 30px 20px;
        .title{
            color: #333;
            font-size: 32px;
            padding: 0 0 18px;
            margin-bottom: 15px;
            border-bottom: 1px solid #ccc;
        }
        .ant-tabs-tabpane{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 950px;
        }
        .tabs_tab{
            font-size: 20px;
        }
        .tabs_view{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-around;
            flex-direction: row;
            width: 888px;
            max-height: 65vh;
            overflow-y: scroll;
            .tabs_content{
                .clientItem{
                    display: inline-block;
                    width: 270px;
                    height: 100px;
                    border: 1px solid #ccc;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: 50%;
                    background-origin: content-box;
                    padding: 10px;
                    margin: 0 15px 25px 0;
                }
            }
        }
    }
</style>
