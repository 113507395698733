<template>
    <div class="content">
        <!--  -->
        <a-carousel arrows :dots="false" autoplay>
            <div class="carousel_view" v-for="(item, i) in imgData" :key="i">
                <div class="carousel_view_img"  :style="{ backgroundImage: 'url(' + item.img + ')' }"></div>
                <div class="slogan slogan2" v-if="item.h3">
                    <h2>{{ item.h1 }}</h2>
                    <h3> {{ item.h2 }}</h3>
                    <h3> {{ item.h3 }}<a-icon type="right-circle" /></h3>
                </div>
                <div class="slogan" v-else>
                    <h1>{{ item.h1 }}</h1>
                    <h2> {{ item.h2 }}<a-icon type="right-circle" /></h2>
                </div>
            </div>
        </a-carousel>
        <div class="content_page1">
            <div class="content_page1_articleTab" v-for="(item, i) in page1Data" :key="i">
                <div class="page1_articleTab_title">
                    <div>{{ item.name }}</div>
                    <a-button  @click="page1Open(item, 1)">更 多</a-button>
                </div>
                <div class="page1_articleTab_content">
                    <div v-for="(item1, i1) in item.list" :key="i1" @click="page1Open(item1, 2)">
                        <div class="top1" v-if="i1 === 0">
                            <a class="a_img" target="_blank">
                                <img
                                    slot="cover"
                                    alt="example"
                                    :src="item1.imgSrc"
                                />
                            </a>
                            <h6>{{ item.createTime }}</h6>
                            <a-tooltip placement="top">
                                <template slot="title">
                                <span>{{ item1.title }}</span>
                                </template>
                                <a class="a_h2" target="_blank"><h2>{{ item1.title }}</h2></a>
                            </a-tooltip>
                        </div>
                        <div v-else>
                            <a-tooltip placement="top">
                                <template slot="title">
                                <span>{{ item1.title }}</span>
                                </template>
                                <a target="_blank">{{item1.title}}</a>
                            </a-tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="page2">
            <h1>工作&服务</h1>
            <p>我们从事标准化与质量研究和服务，我们的工作覆盖多个领域与行业</p>
            <div class="page2_view">
                <div
                class="page2_view_card" 
                v-for="(item, index) in page2Data" 
                :key="index" 
                :class="page2CardIndex === index?'page2_view_card_Open':''"
                :style="page2Style(index)"
                @click="page2Card(index)">
                    <span>
                        <div :style="{backgroundImage: item.backgroundImage}"></div>
                        <h1>{{ item.title }}</h1>
                        <p>{{ item.content }}</p>
                        <img
                            class="ImgOpen"
                            slot="cover"
                            alt="example"
                            src="@/assets/image/content/ImgOpen.png"
                        />
                    </span>
                    <img
                        class="ImgClose"
                        slot="cover"
                        alt="example"
                        src="@/assets/image/content/ImgClose.png"
                    />
                    <div class="closeBtn" @click.stop="page2Card(-1)">
                        <span>关闭</span>
                        <img
                            slot="cover"
                            alt="example"
                            src="@/assets/image/close.png"
                        />
                    </div>
                    <div class="ChildrenFunctionContainer" v-if="item.list.length > 0">
                        <h2>优质案例</h2>
                        <div>
                            <div v-for="(item1, i1) in item.list" :key="i1" @click="page2Open(index)">
                                <img :src="item1.src">
                                <span>{{ item1.content }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="page3">
            <h1>我们随时随地响应您的需求</h1>
            <p>为您提供标准、质量、检测、认证、咨询完整解决方案</p>
            <div class="page2_view">
                <div
                    v-for="(item, index) in page3Data" 
                    :key="index" 
                    :class="page3CardIndex === index?'page3_view_card_Open':''"
                    :style="page3Style(index)"
                    @click="page3Card(index)">
                    <div>
                        <div class="divItemBg"  :style="{background: item.background}"></div>
                        <div class="header">
                            <img
                                class="ImgOpen"
                                slot="cover"
                                alt="example"
                                :src="item.logSrc"
                            />
                            <h1>
                                <div>{{ item.title }}</div>
                                <p>{{ item.content }}</p>
                            </h1>
                        </div>
                        <div class="closeBtn" @click.stop="page3Card(-1)">
                            <span>关闭</span>
                            <img
                                slot="cover"
                                alt="example"
                                src="@/assets/image/close.png"
                            />
                        </div>
                        <div class="ChildrenFunctionContainer">
                            <div v-for="(item1, i1) in item.list" :key="i1" :style="{background: item1.background}">
                                <h1>{{ item1.title }}</h1>
                                <span>{{ item1.content }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel">
                <a-carousel arrows :dots="false" :slides-to-show="3" :slides-to-scroll="1">
                    <div
                        slot="prevArrow"
                        slot-scope="props"
                        class="custom-slick-arrow"
                        style="left: 10px;zIndex: 1"
                        >
                        <a-icon type="left-circle" />
                    </div>
                    <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 10px">
                        <a-icon type="right-circle" />
                    </div>
                    <div class="carousel_view" v-for="(item, i) in page3CarouselData[page3CardIndex]" :key="i" @click="modalVisible(item)">
                        <img
                            slot="cover"
                            alt="example"
                            :src="item.src"
                        />
                        <span>{{ item.title }}</span>
                    </div>
                </a-carousel>
                <a-modal class="page3Modal" v-model="visible" :title="visibleData.title" :footer="null" width="800px">
                    <p>{{ visibleData.content }}
                        <img
                            style="width: 100%;"
                            slot="cover"
                            alt="example"
                            :src="visibleData.src"
                        />
                    </p>
                </a-modal>
            </div>
        </div>
    </div>
</template>
  
<script>
import { getShowAll, getNewsList } from "@/api/content";
import { getImgRegex } from "@/utils/index";
export default {
    data() {
        return {
            imgData:[{
                    img: require('@/assets/image/content/carousel1.png'),
                    type: 1,
                    h1:'衣 · 食 · 住 · 行',
                    h2:'标准 & 质量同行'
                },{
                    img: require('@/assets/image/content/carousel2.png'),
                    type: 1,
                    h1:'衣 · 食 · 住 · 行',
                    h2:'标准 & 质量同行'
                },{
                    img: require('@/assets/image/content/carousel3.png'),
                    type: 1,
                    h1:'衣 · 食 · 住 · 行',
                    h2:'标准 & 质量同行'
                },{
                    img: require('@/assets/image/content/carousel4.png'),
                    type: 1,
                    h1:'衣 · 食 · 住 · 行',
                    h2:'标准 & 质量同行'
                },{
                    img: require('@/assets/image/content/carousel5.png'),
                    type: 2,
                    h1:'湖北省标准化学会',
                    h2:'会致力于全省标准化事业的社会团体',
                    h3:'了解更多'
                }
            ],
            page1Data:[],
            page2CardIndex: '-1',
            page2Data:[{
                backgroundImage: `url(${require('@/assets/image/content/page2_1.png')})`,
                title:'事业发展规划部',
                content:'省标质院事业发展规划部（以下简称“事业部”）是负责、协调全院业务工作开展、管理收入类经济合同工作开展的业务部门。负责市场开发、拓展、各业务部门商务信息收集整理、经济类收入合同的日常管理，合同登记',
                list:[]
            },{
                backgroundImage: `url(${require('@/assets/image/content/page2_2.png')})`,
                title:'标准文献馆',
                content:'全省标准文献资源的规划与建设、全省标准化管理工作技术支撑、相关领域标准化技术咨询服务致力于建设完善具有湖北特色的标准文献体系、研究标准数据挖掘分析技术、做好标准化行政管理技术支撑开展安全生产监管、国土资源管理、农林等领域的标准化顶层设计与应用研究',
                list:[{
                    src: require('@/assets/image/content/page2_2_1.png'),
                    content: '助力我省安全生产精细化管理与标准化建设'
                },{
                    src: require('@/assets/image/content/page2_2_2.png'),
                    content: '标准大数据应用为湖北高质量发展奠定基础'
                },{
                    src: require('@/assets/image/content/page2_2_3.png'),
                    content: '标准推动“厕所革命”'
                }]
            },{
                backgroundImage: `url(${require('@/assets/image/content/page2_3.png')})`,
                title:'物品编码部',
                content:'物品编码管理、技术研究与商品条码系统成员服务的专职部门履行中国物品编码中心湖北分中心和湖北省条码标识质量监督检验站的工作职能承担湖北省地理标志产品标准化技术委员会秘书处工作职责',
                list:[{
                    src: require('@/assets/image/content/page2_3_1.png'),
                    content: '条码微站助力备战“双十一”'
                },{
                    src: require('@/assets/image/content/page2_3_2.png'),
                    content: '绿色通道解决审核燃眉之急'
                },{
                    src: require('@/assets/image/content/page2_3_3.png'),
                    content: '真诚服务挽回百万损失'
                }]
            },{
                backgroundImage: `url(${require('@/assets/image/content/page2_4.png')})`,
                title:'高新技术与产业标准化研究所',
                content:'专业从事高新技术领域研究的标准化研究所围绕着高新技术与战略性新兴产业、开展科技成果转化、技术专利与标准结合、团体标准运营、标准第三方评估、检测与标准验证一体化实验室孵化等专业领域的标准化科研与服务工作',
                list:[{
                    src: require('@/assets/image/content/page2_4_1.png'),
                    content: '以标准打破国外垄断保护民族产业'
                },{
                    src: require('@/assets/image/content/page2_4_2.png'),
                    content: '以标准促进传统行业转型升级'
                },{
                    src: require('@/assets/image/content/page2_4_3.png'),
                    content: '以标准突显湖北行业领先地位'
                }]
            },{
                backgroundImage: `url(${require('@/assets/image/content/page2_5.png')})`,
                title:'现代服务业与公共管理标准化研究所',
                content:'从事现代服务业和公共管理标准化研究及技术服务的专职部门致力于开展生活性服务业、生产性服务业和社会管理与公共服务等相关服务标准化前沿理论、顶层政策设计以及标准化成果的应用研究',
                list:[{
                    src: require('@/assets/image/content/page2_5_1.png'),
                    content: '现代服务业-以标准助力养老服务质量'
                },{
                    src: require('@/assets/image/content/page2_5_2.png'),
                    content: '现代服务业-以改革工作为基础助力“数字政府”建设'
                },{
                    src: require('@/assets/image/content/page2_5_3.png'),
                    content: '现代服务业-支撑湖北机关事务管理标准化工作树立标杆'
                }]
            },{
                backgroundImage: `url(${require('@/assets/image/content/page2_6.png')})`,
                title:'国际标准化研究所',
                content:'从事技术性贸易措施研究、WTO通报咨询、国际标准化合作与研究、检验检测研究和湖北省标准学会的专职部门致力于开展出口企业技术性贸易措施研究与应对、与国际标准化组织建立联系并开展合作、检验检测平台搭建与宏观研究和湖北省标准化学会运维保障与团体标准管理等应用研究',
                list:[{
                    src: require('@/assets/image/content/page2_6_1.png'),
                    content: '以试点推动城市与国际可持续发展标准接轨'
                },{
                    src: require('@/assets/image/content/page2_6_2.png'),
                    content: '以标准国际合作推动激光产业全球品牌打造'
                },{
                    src: require('@/assets/image/content/page2_6_3.png'),
                    content: '以标准中英合作布局智慧社区产业发展前端'
                }]
            },{
                backgroundImage: `url(${require('@/assets/image/content/page2_7.png')})`,
                title:'质量发展与质量安全研究所',
                content:'从事质量发展和质量安全研究及技术服务的专职部门围绕省委省政府“质量强省”战略，开展宏观质量监管、中观质量发展和微观质量提升的顶层政策设计、前沿理论研究、标准制修订和市场服务工作履行湖北省缺陷产品管理中心职能，为缺陷产品的发现、调查、确认和召回等管理工作提供技术支持开展湖北省家用汽车产品三包责任争议技术专家咨询工作',
                list:[{
                    src: require('@/assets/image/content/page2_7_1.png'),
                    content: '以召回工作推动产品质量提升'
                },{
                    src: require('@/assets/image/content/page2_7_2.png'),
                    content: '以“标尺”助推高质量发展'
                },{
                    src: require('@/assets/image/content/page2_7_3.png'),
                    content: '以三包政策化解我省汽车产品质量纠纷'
                }]
            },{
                backgroundImage: `url(${require('@/assets/image/content/page2_8.png')})`,
                title:'资源与环境标准化研究所',
                content:'资源与环境标准化研究及技术服务的专职部门致力于节能、节水、生态文明以及环保等领域标准化前沿理论、顶层政策设计以及标准化成果的应用研究',
                list:[{
                    src: require('@/assets/image/content/page2_8_1.png'),
                    content: '以标准支撑绿色咸宁发展'
                },{
                    src: require('@/assets/image/content/page2_8_2.png'),
                    content: '以标准规范湖北省光伏产业发展'
                }]
            },{
                backgroundImage: `url(${require('@/assets/image/content/page2_9.png')})`,
                title:'物品编码部',
                content:'物品编码管理、技术研究与商品条码系统成员服务的专职部门履行中国物品编码中心湖北分中心和湖北省条码标识质量监督检验站的工作职能承担湖北省地理标志产品标准化技术委员会秘书处工作职责',
                list:[{
                    src: require('@/assets/image/content/page2_9_1.png'),
                    content: '全国物流标准化城市（黄石市）试点技术支撑'
                },{
                    src: require('@/assets/image/content/page2_9_2.png'),
                    content: '以标准助推冷链、快递业高质量发展'
                }]
            }],
            page3CardIndex: '-1',
            page3Data:[{
                logSrc: require('@/assets/image/content/page3_1.png'),
                background: 'rgb(0, 104, 183)',
                title: '标准服务',
                content: '致力于“标准文献服务、各类标准制修订、标准化试点示范咨询与服务、国际标准化活动与服务”等，为标准化实践提供最佳的解决方案',
                list:[{
                    title: '1',
                    background: 'rgb(0, 102, 204)',
                    content: '标准文献服务',
                },{
                    title: '2',
                    background: 'rgb(255, 109, 51)',
                    content: '各类标准制修订',
                },{
                    title: '3',
                    background: 'rgb(2, 112, 104)',
                    content: '标准化试点示范咨询与服务',
                },{
                    title: '4',
                    background: 'rgb(5, 139, 73)',
                    content: '国际标准化活动与服务',
                }]
            },{
                logSrc: require('@/assets/image/content/page3_2.png'),
                background: 'rgb(27, 194, 112)',
                title: '标准化及应用',
                content: '致力于“标准化咨询、标准化体系规划、标准实施、标准化政策研究、码应用”等服务，为标准化及应用提供最佳的解决方案',
                list:[{
                    title: '1',
                    background: 'rgb(0, 102, 204)',
                    content: '标准化咨询',
                },{
                    title: '2',
                    background: 'rgb(255, 109, 51)',
                    content: '标准化体系规划',
                },{
                    title: '3',
                    background: 'rgb(2, 112, 104)',
                    content: '标准实施',
                },{
                    title: '4',
                    background: 'rgb(5, 139, 73)',
                    content: '标准化政策研究',
                },{
                    title: '5',
                    background: 'rgb(134, 8, 223)',
                    content: '码应用',
                }]
            },{
                logSrc: require('@/assets/image/content/page3_3.png'),
                background: 'rgb(20, 198, 179)',
                title: '检测服务',
                content: '致力于“检测咨询、检测信息平台、检测宏观研究、检测实验室建设”等服务，为检验检测实践提供最佳的解决方案',
                list:[{
                    title: '1',
                    background: 'rgb(0, 102, 204)',
                    content: '检测咨询',
                },{
                    title: '2',
                    background: 'rgb(255, 109, 51)',
                    content: '检测信息平台',
                },{
                    title: '3',
                    background: 'rgb(2, 112, 104)',
                    content: '检测宏观研究',
                },{
                    title: '4',
                    background: 'rgb(5, 139, 73)',
                    content: '检测实验室建设',
                }]
            },{
                logSrc: require('@/assets/image/content/page3_4.png'),
                background: 'rgb(255, 103, 102)',
                title: '认证服务',
                content: '致力于“体系（服务、信息安全）认证、质量管理体系服务、标准相关的评审认证咨询培训”等服务，为认证服务提供最佳的解决方案',
                list:[{
                    title: '1',
                    background: 'rgb(0, 102, 204)',
                    content: '体系（服务、信息安全）认证',
                },{
                    title: '2',
                    background: 'rgb(255, 109, 51)',
                    content: '质量管理体系服务',
                },{
                    title: '3',
                    background: 'rgb(2, 112, 104)',
                    content: '标准相关的评审认证咨询培训',
                }]
            },{
                logSrc: require('@/assets/image/content/page3_5.png'),
                background: 'rgb(255, 153, 102)',
                title: '质量服务',
                content: '致力于“质量政策咨询、企业质量提升、质量技术服务、缺陷管理、质量强省示范县、质量奖、品牌建设服务”等服务，为质量提升提供最佳的解决方案',
                list:[{
                    title: '1',
                    background: 'rgb(0, 102, 204)',
                    content: '质量政策咨询',
                },{
                    title: '2',
                    background: 'rgb(255, 109, 51)',
                    content: '企业质量提升',
                },{
                    title: '3',
                    background: 'rgb(2, 112, 104)',
                    content: '质量技术服务',
                },{
                    title: '4',
                    background: 'rgb(5, 139, 73)',
                    content: '缺陷管理',
                }]
            },{
                logSrc: require('@/assets/image/content/page3_6.png'),
                background: 'rgb(255, 204, 51)',
                title: '咨询服务',
                content: '致力于“管理政策咨询、培训”等服务，为咨询服务实践提供最佳的解决方案',
                list:[{
                    title: '1',
                    background: 'rgb(0, 102, 204)',
                    content: '管理政策咨询',
                },{
                    title: '2',
                    background: 'rgb(255, 109, 51)',
                    content: '培训',
                }]
            }],
            page3CarouselData:{
                0:[{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'标准大数据',
                    content:'面对全球范围内运用大数据推动经济发展、完善社会治理、提升政府服务和监管能力的大趋势，在标准化研究领域，运用大数据技术对各种数据进行筛选、分析，可以为标准化工作提供强有力的指导和支持。自 2016年起，标准文献馆每年都会出具针对上一年度的 《湖北省标准大数据分析报告》，围绕《湖北省国民经济和社会发展第十三个五年规划纲要》、湖北省人民政府《关于深化标准化工作改革创新的意见》 确定的发展目标和重点任务，通过大数据技术全面分析和立体展示我省标准化工作开展情况，认真总结工作成果及经验，探讨标准化与经济和社会发展的关系，为湖北省未来标准化工作的开展方向提供了权威的数据支撑。'
                },{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'标准推动“厕所革命”',
                    content:'《农村无害化厕所建造技术指南》地方标准的编制是我馆参与湖北省“厕所革命”的具体工作，在标准编制过程中，广泛征求意见，确保标准的适用性和可操作性，推动我省“厕所革命”相关产品生产企业按新标准提升产品质量。该标准的实施意味着农村无害化厕所新建、改建及验收，有了统一标准。同时，我省农村厕改工作的招标、采购、竞争性谈判以及督查、验收等工作都将以此为标准。确保到 2020 年我省将全面完成 200 万户农户无害化厕所新建、130 万户农户厕所改造提升以及25063 座农村公共厕所建设改造任务。'
                },{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'以标准支撑绿色咸宁发展',
                    content:'为了固化绿色咸宁建设的试点经验和成果，加快推进绿色咸宁建设，为其它示范区建设经验和模式的“可复制、可推广”提供有效载体，为全省生态文明建设提供操作指南，按照“绿色城镇、绿色产业、绿色环境”三位一体的思路，协助咸宁市建立了“绿色咸宁”标准化体系框架，收集整理了 757个相关标准。“咸宁标准”对促进绿色产业发展作用显著，尤其在现代农业、生态保护和公共服务标准体系建设上取得较好的效果，制定农业地方标准 43 项，先后创建 1 个国家级有机产品示范市，14 个国家级农业标准化示范区，9 个省级标准化示范区，“三品一标”农产品达到 441 个。2018 年咸宁市空气质量在全国 169 个重点城市中位居第 19 为，是全省唯一入选“20 个空气质量相对较好城市”的地级市。实施公共交通安全、节能、环保等强制性标准，成为中部六省第一个实现全部纯电动公交运营的城市，是全国首个国家级城市公共交通标准化试点市。'
                },{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'以标准助力养老服务质量',
                    content:'联合省民政局从 2014 年开始着手养老标准化建设，先后出台了 《养老机构服务质量规范》、《养老机构护理员日常服务操作规范》等5 项标准，对养老机构每项服务的质量控制、改进作出了具体要求，对养老机构护理员日常服务操作进行了规范，对各类服务项目进行了程序化的规定。参与编写人社部培训教材 《养老机构标准化实用手册》 和专著 《家庭农场标准化实践》。'
                },{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'支撑湖北机关事务',
                    content:'配合省机关事务局开展省市两级机关事务标准体系建设方案设计，指导各处室和局属单位开展标准化体系建设，推进重点职能领域地方标准研制，推动全省机关事务系统开展国家级、省级标准化试点创建工作。我省机关事务管理标准化工作先后获国务院副秘书长、国管局局长李保荣，湖北省副省长黄楚平高度评价，4 次在全国机关事务管理工作会上作典型发言，被国管局作为“湖北经验”在全国机关事务系统中推广。'
                },{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'助力我省安全生产',
                    content:'协助湖北省应急管理厅筹备成立“湖北省安全生产标准化技术委员会”,会同相关行业部门、重点院校、企业讨论通过了 《湖北省安全生产标准化技术要员会章程》；组织专班运用标准化理论与方法、搭建了覆盖全行业领域的安全生产标准体系-《企业安全生产监管标准》汇编,基本涵盖了国内安全生产标准的重要信息,为监管部门的管理和企业的安全生产提供了技术支撑。标准数据推送系统的建立,实现了与湖北安全生产综合信息平台的数据对接与共享。'
                }],
                1:[{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'全国物流标准化城市',
                    content:'2016年开始，协助黄石市商务委员会、质监局、财政局完善国家试点项目整体方案，参与黄石市三单位组织的试点企业2轮立项评审与结项评审工作。 2017年，协助试点企业申报立项、研制5项省级物流地方标准，涉及城市配送、鞋服仓储、医药仓储、公路运输、玻璃容器包装储运领域。承担《黄石市商贸物流标准体系研究》政采项目，汇总现行国标、行标与新研制的企标共计300余项。协助湖北浙联鞋服公司、湖北众联物流公司完成仓库功能分区、划线与施工工作；指导劲酒公司、劲鹏制盖公司、浙联鞋服公司按照包装标准模数改善产品外包装盒。 通过物流标准化的宣贯与持续实施改进，黄石市经济与社会效益显著，综合物流成本下降3.91%，平均货损率降低0.15%，物流装卸效率平均提高2.02倍，车辆周转效率提高2.39倍以上，装卸搬运单位成本下降45%'
                },{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'以“互联网+放管服”',
                    content:'2017 年以来，省标质院抓紧落实省政府关于“互联网 + 放管服”改革的有关部署要求，精心组织，深入研究，扎实推进行政审批标准化工作，为实现全省政务服务“一张网”提供标准化技术支撑。研制发布 《行政许可事项编码规则》、《行政许可事项服务指南编写规范》、《行政许可事项审查细则编写规范》 等三项基础标准为先导，相继确定了十大类行政权力和公共服务事项标准化要素，统一了全省网上政务服务标准化工作范式。配合省编办基本完成省市县乡四级清单体系搭建，指导各地各部门编制录入服务指南和审查细则，配合省政府开展政务服务“一张网”建设进度情况月度考核通报。联合国家行政审批标准化工作组共同研制省级地方标准 《行政许可事项网上办理质效评价规范》，助力省市县乡村五级政务服务标准化全覆盖。作为全省“数字政府”建设顶层设计专班成员，为“数字政府”建设提供标准化技术支撑'
                },{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'以标准促进传统行业转型升级',
                    content:'为促进传统锻造耗能产业转向全球产业链条中的高附加值产业，解决湖北三环锻造有限公司在标准研制和标准实施过程中遇到的难题，多次组织相关专家深入企业，通过标准化公共服务平台和基地标准供需服务的线上服务平台，成功解决锻造企业产能过剩、同质化竞争严重、自动化和智能化程度偏低等难题。转化形成的 《模锻件数字化车间生产过程控制系统功能要求》、《模锻件锻造过程信息追溯通用要求》、《汽车复杂锻件余热利用技术规范》 等系列湖北省地方标准，固化了锻造领域智能化生产的技术路线，推动智能技术改造传统制造业、推动工业化和信息化的深度融合，进一步促进我省汽车零部件锻造领域走可持续发展和绿色发展的道路。'
                },{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'以标准打破国外垄断保护民族产业',
                    content:'为了增强我国光电子产业原材料的话语权，利用创新基地搭建的TC/SC工作站，为企业与标准化委员会提供在线的标准供需对接服务，协助晶星科技公司开展二氯二氢硅和四氯化硅技术成果转化为技术标准工作。转化形成的 《电子工业用二氯二氢硅》 和《电子工业用四氯化硅》 两项国家标准，填补了我国缺乏半导体原材料生产工艺和分析检测技术的空白，改变了以往国产产品因缺标准无法直接销售，都采用“先出口再进口的曲线运作模式”，解决了我国半导体和集成电路行业受制约的局面。该标准将改变目前先出口再进口的曲线模式，预计每年能为我国节省进口二氯二氢硅总额约1亿元，节省进口四氯化硅总额8.5亿元。'
                },{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'以标准规范 湖北省光伏产业发展',
                    content:'为了落实国家能源局发布的 《关于可再生能源发展“十三五规划实施的指导意见”》，促进湖北省光伏产业的合作与发展，推动建设完整的产业链和健康、成熟的市场，提升产业的整体竞争力，创新基地积极开展产学研协作的创新合作模式，联合全国 28 家光伏产业上下游机构成立了湖北省光伏产业标准联盟，发布实施一项团体标准 《湖北省光伏扶贫电站建设验收规范》，填补了我省在光伏扶贫电站建设验收领域的空白，对规范我省光伏电站特别是扶贫电站的建设提供了参考。'
                }],
                2:[{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'全国物流标准化城市',
                    content:'2016年开始，协助黄石市商务委员会、质监局、财政局完善国家试点项目整体方案，参与黄石市三单位组织的试点企业2轮立项评审与结项评审工作。 2017年，协助试点企业申报立项、研制5项省级物流地方标准，涉及城市配送、鞋服仓储、医药仓储、公路运输、玻璃容器包装储运领域。承担《黄石市商贸物流标准体系研究》政采项目，汇总现行国标、行标与新研制的企标共计300余项。协助湖北浙联鞋服公司、湖北众联物流公司完成仓库功能分区、划线与施工工作；指导劲酒公司、劲鹏制盖公司、浙联鞋服公司按照包装标准模数改善产品外包装盒。 通过物流标准化的宣贯与持续实施改进，黄石市经济与社会效益显著，综合物流成本下降3.91%，平均货损率降低0.15%，物流装卸效率平均提高2.02倍，车辆周转效率提高2.39倍以上，装卸搬运单位成本下降45%'
                },{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'以“互联网+放管服”',
                    content:'2017 年以来，省标质院抓紧落实省政府关于“互联网 + 放管服”改革的有关部署要求，精心组织，深入研究，扎实推进行政审批标准化工作，为实现全省政务服务“一张网”提供标准化技术支撑。研制发布 《行政许可事项编码规则》、《行政许可事项服务指南编写规范》、《行政许可事项审查细则编写规范》 等三项基础标准为先导，相继确定了十大类行政权力和公共服务事项标准化要素，统一了全省网上政务服务标准化工作范式。配合省编办基本完成省市县乡四级清单体系搭建，指导各地各部门编制录入服务指南和审查细则，配合省政府开展政务服务“一张网”建设进度情况月度考核通报。联合国家行政审批标准化工作组共同研制省级地方标准 《行政许可事项网上办理质效评价规范》，助力省市县乡村五级政务服务标准化全覆盖。作为全省“数字政府”建设顶层设计专班成员，为“数字政府”建设提供标准化技术支撑'
                },{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'以标准促进传统行业转型升级',
                    content:'为促进传统锻造耗能产业转向全球产业链条中的高附加值产业，解决湖北三环锻造有限公司在标准研制和标准实施过程中遇到的难题，多次组织相关专家深入企业，通过标准化公共服务平台和基地标准供需服务的线上服务平台，成功解决锻造企业产能过剩、同质化竞争严重、自动化和智能化程度偏低等难题。转化形成的 《模锻件数字化车间生产过程控制系统功能要求》、《模锻件锻造过程信息追溯通用要求》、《汽车复杂锻件余热利用技术规范》 等系列湖北省地方标准，固化了锻造领域智能化生产的技术路线，推动智能技术改造传统制造业、推动工业化和信息化的深度融合，进一步促进我省汽车零部件锻造领域走可持续发展和绿色发展的道路。'
                },{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'以标准打破国外垄断保护民族产业',
                    content:'为了增强我国光电子产业原材料的话语权，利用创新基地搭建的TC/SC工作站，为企业与标准化委员会提供在线的标准供需对接服务，协助晶星科技公司开展二氯二氢硅和四氯化硅技术成果转化为技术标准工作。转化形成的 《电子工业用二氯二氢硅》 和《电子工业用四氯化硅》 两项国家标准，填补了我国缺乏半导体原材料生产工艺和分析检测技术的空白，改变了以往国产产品因缺标准无法直接销售，都采用“先出口再进口的曲线运作模式”，解决了我国半导体和集成电路行业受制约的局面。该标准将改变目前先出口再进口的曲线模式，预计每年能为我国节省进口二氯二氢硅总额约1亿元，节省进口四氯化硅总额8.5亿元。'
                },{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'以标准规范 湖北省光伏产业发展',
                    content:'为了落实国家能源局发布的 《关于可再生能源发展“十三五规划实施的指导意见”》，促进湖北省光伏产业的合作与发展，推动建设完整的产业链和健康、成熟的市场，提升产业的整体竞争力，创新基地积极开展产学研协作的创新合作模式，联合全国 28 家光伏产业上下游机构成立了湖北省光伏产业标准联盟，发布实施一项团体标准 《湖北省光伏扶贫电站建设验收规范》，填补了我省在光伏扶贫电站建设验收领域的空白，对规范我省光伏电站特别是扶贫电站的建设提供了参考。'
                }],
                3:[{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'全国物流标准化城市',
                    content:'2016年开始，协助黄石市商务委员会、质监局、财政局完善国家试点项目整体方案，参与黄石市三单位组织的试点企业2轮立项评审与结项评审工作。 2017年，协助试点企业申报立项、研制5项省级物流地方标准，涉及城市配送、鞋服仓储、医药仓储、公路运输、玻璃容器包装储运领域。承担《黄石市商贸物流标准体系研究》政采项目，汇总现行国标、行标与新研制的企标共计300余项。协助湖北浙联鞋服公司、湖北众联物流公司完成仓库功能分区、划线与施工工作；指导劲酒公司、劲鹏制盖公司、浙联鞋服公司按照包装标准模数改善产品外包装盒。 通过物流标准化的宣贯与持续实施改进，黄石市经济与社会效益显著，综合物流成本下降3.91%，平均货损率降低0.15%，物流装卸效率平均提高2.02倍，车辆周转效率提高2.39倍以上，装卸搬运单位成本下降45%'
                },{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'以“互联网+放管服”',
                    content:'2017 年以来，省标质院抓紧落实省政府关于“互联网 + 放管服”改革的有关部署要求，精心组织，深入研究，扎实推进行政审批标准化工作，为实现全省政务服务“一张网”提供标准化技术支撑。研制发布 《行政许可事项编码规则》、《行政许可事项服务指南编写规范》、《行政许可事项审查细则编写规范》 等三项基础标准为先导，相继确定了十大类行政权力和公共服务事项标准化要素，统一了全省网上政务服务标准化工作范式。配合省编办基本完成省市县乡四级清单体系搭建，指导各地各部门编制录入服务指南和审查细则，配合省政府开展政务服务“一张网”建设进度情况月度考核通报。联合国家行政审批标准化工作组共同研制省级地方标准 《行政许可事项网上办理质效评价规范》，助力省市县乡村五级政务服务标准化全覆盖。作为全省“数字政府”建设顶层设计专班成员，为“数字政府”建设提供标准化技术支撑'
                },{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'以标准促进传统行业转型升级',
                    content:'为促进传统锻造耗能产业转向全球产业链条中的高附加值产业，解决湖北三环锻造有限公司在标准研制和标准实施过程中遇到的难题，多次组织相关专家深入企业，通过标准化公共服务平台和基地标准供需服务的线上服务平台，成功解决锻造企业产能过剩、同质化竞争严重、自动化和智能化程度偏低等难题。转化形成的 《模锻件数字化车间生产过程控制系统功能要求》、《模锻件锻造过程信息追溯通用要求》、《汽车复杂锻件余热利用技术规范》 等系列湖北省地方标准，固化了锻造领域智能化生产的技术路线，推动智能技术改造传统制造业、推动工业化和信息化的深度融合，进一步促进我省汽车零部件锻造领域走可持续发展和绿色发展的道路。'
                },{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'以标准打破国外垄断保护民族产业',
                    content:'为了增强我国光电子产业原材料的话语权，利用创新基地搭建的TC/SC工作站，为企业与标准化委员会提供在线的标准供需对接服务，协助晶星科技公司开展二氯二氢硅和四氯化硅技术成果转化为技术标准工作。转化形成的 《电子工业用二氯二氢硅》 和《电子工业用四氯化硅》 两项国家标准，填补了我国缺乏半导体原材料生产工艺和分析检测技术的空白，改变了以往国产产品因缺标准无法直接销售，都采用“先出口再进口的曲线运作模式”，解决了我国半导体和集成电路行业受制约的局面。该标准将改变目前先出口再进口的曲线模式，预计每年能为我国节省进口二氯二氢硅总额约1亿元，节省进口四氯化硅总额8.5亿元。'
                },{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'以标准规范 湖北省光伏产业发展',
                    content:'为了落实国家能源局发布的 《关于可再生能源发展“十三五规划实施的指导意见”》，促进湖北省光伏产业的合作与发展，推动建设完整的产业链和健康、成熟的市场，提升产业的整体竞争力，创新基地积极开展产学研协作的创新合作模式，联合全国 28 家光伏产业上下游机构成立了湖北省光伏产业标准联盟，发布实施一项团体标准 《湖北省光伏扶贫电站建设验收规范》，填补了我省在光伏扶贫电站建设验收领域的空白，对规范我省光伏电站特别是扶贫电站的建设提供了参考。'
                }],
                4:[{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'全国物流标准化城市',
                    content:'2016年开始，协助黄石市商务委员会、质监局、财政局完善国家试点项目整体方案，参与黄石市三单位组织的试点企业2轮立项评审与结项评审工作。 2017年，协助试点企业申报立项、研制5项省级物流地方标准，涉及城市配送、鞋服仓储、医药仓储、公路运输、玻璃容器包装储运领域。承担《黄石市商贸物流标准体系研究》政采项目，汇总现行国标、行标与新研制的企标共计300余项。协助湖北浙联鞋服公司、湖北众联物流公司完成仓库功能分区、划线与施工工作；指导劲酒公司、劲鹏制盖公司、浙联鞋服公司按照包装标准模数改善产品外包装盒。 通过物流标准化的宣贯与持续实施改进，黄石市经济与社会效益显著，综合物流成本下降3.91%，平均货损率降低0.15%，物流装卸效率平均提高2.02倍，车辆周转效率提高2.39倍以上，装卸搬运单位成本下降45%'
                },{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'以“互联网+放管服”',
                    content:'2017 年以来，省标质院抓紧落实省政府关于“互联网 + 放管服”改革的有关部署要求，精心组织，深入研究，扎实推进行政审批标准化工作，为实现全省政务服务“一张网”提供标准化技术支撑。研制发布 《行政许可事项编码规则》、《行政许可事项服务指南编写规范》、《行政许可事项审查细则编写规范》 等三项基础标准为先导，相继确定了十大类行政权力和公共服务事项标准化要素，统一了全省网上政务服务标准化工作范式。配合省编办基本完成省市县乡四级清单体系搭建，指导各地各部门编制录入服务指南和审查细则，配合省政府开展政务服务“一张网”建设进度情况月度考核通报。联合国家行政审批标准化工作组共同研制省级地方标准 《行政许可事项网上办理质效评价规范》，助力省市县乡村五级政务服务标准化全覆盖。作为全省“数字政府”建设顶层设计专班成员，为“数字政府”建设提供标准化技术支撑'
                },{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'以标准促进传统行业转型升级',
                    content:'为促进传统锻造耗能产业转向全球产业链条中的高附加值产业，解决湖北三环锻造有限公司在标准研制和标准实施过程中遇到的难题，多次组织相关专家深入企业，通过标准化公共服务平台和基地标准供需服务的线上服务平台，成功解决锻造企业产能过剩、同质化竞争严重、自动化和智能化程度偏低等难题。转化形成的 《模锻件数字化车间生产过程控制系统功能要求》、《模锻件锻造过程信息追溯通用要求》、《汽车复杂锻件余热利用技术规范》 等系列湖北省地方标准，固化了锻造领域智能化生产的技术路线，推动智能技术改造传统制造业、推动工业化和信息化的深度融合，进一步促进我省汽车零部件锻造领域走可持续发展和绿色发展的道路。'
                },{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'以标准打破国外垄断保护民族产业',
                    content:'为了增强我国光电子产业原材料的话语权，利用创新基地搭建的TC/SC工作站，为企业与标准化委员会提供在线的标准供需对接服务，协助晶星科技公司开展二氯二氢硅和四氯化硅技术成果转化为技术标准工作。转化形成的 《电子工业用二氯二氢硅》 和《电子工业用四氯化硅》 两项国家标准，填补了我国缺乏半导体原材料生产工艺和分析检测技术的空白，改变了以往国产产品因缺标准无法直接销售，都采用“先出口再进口的曲线运作模式”，解决了我国半导体和集成电路行业受制约的局面。该标准将改变目前先出口再进口的曲线模式，预计每年能为我国节省进口二氯二氢硅总额约1亿元，节省进口四氯化硅总额8.5亿元。'
                },{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'以标准规范 湖北省光伏产业发展',
                    content:'为了落实国家能源局发布的 《关于可再生能源发展“十三五规划实施的指导意见”》，促进湖北省光伏产业的合作与发展，推动建设完整的产业链和健康、成熟的市场，提升产业的整体竞争力，创新基地积极开展产学研协作的创新合作模式，联合全国 28 家光伏产业上下游机构成立了湖北省光伏产业标准联盟，发布实施一项团体标准 《湖北省光伏扶贫电站建设验收规范》，填补了我省在光伏扶贫电站建设验收领域的空白，对规范我省光伏电站特别是扶贫电站的建设提供了参考。'
                }],
                5:[{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'全国物流标准化城市',
                    content:'2016年开始，协助黄石市商务委员会、质监局、财政局完善国家试点项目整体方案，参与黄石市三单位组织的试点企业2轮立项评审与结项评审工作。 2017年，协助试点企业申报立项、研制5项省级物流地方标准，涉及城市配送、鞋服仓储、医药仓储、公路运输、玻璃容器包装储运领域。承担《黄石市商贸物流标准体系研究》政采项目，汇总现行国标、行标与新研制的企标共计300余项。协助湖北浙联鞋服公司、湖北众联物流公司完成仓库功能分区、划线与施工工作；指导劲酒公司、劲鹏制盖公司、浙联鞋服公司按照包装标准模数改善产品外包装盒。 通过物流标准化的宣贯与持续实施改进，黄石市经济与社会效益显著，综合物流成本下降3.91%，平均货损率降低0.15%，物流装卸效率平均提高2.02倍，车辆周转效率提高2.39倍以上，装卸搬运单位成本下降45%'
                },{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'以“互联网+放管服”',
                    content:'2017 年以来，省标质院抓紧落实省政府关于“互联网 + 放管服”改革的有关部署要求，精心组织，深入研究，扎实推进行政审批标准化工作，为实现全省政务服务“一张网”提供标准化技术支撑。研制发布 《行政许可事项编码规则》、《行政许可事项服务指南编写规范》、《行政许可事项审查细则编写规范》 等三项基础标准为先导，相继确定了十大类行政权力和公共服务事项标准化要素，统一了全省网上政务服务标准化工作范式。配合省编办基本完成省市县乡四级清单体系搭建，指导各地各部门编制录入服务指南和审查细则，配合省政府开展政务服务“一张网”建设进度情况月度考核通报。联合国家行政审批标准化工作组共同研制省级地方标准 《行政许可事项网上办理质效评价规范》，助力省市县乡村五级政务服务标准化全覆盖。作为全省“数字政府”建设顶层设计专班成员，为“数字政府”建设提供标准化技术支撑'
                },{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'以标准促进传统行业转型升级',
                    content:'为促进传统锻造耗能产业转向全球产业链条中的高附加值产业，解决湖北三环锻造有限公司在标准研制和标准实施过程中遇到的难题，多次组织相关专家深入企业，通过标准化公共服务平台和基地标准供需服务的线上服务平台，成功解决锻造企业产能过剩、同质化竞争严重、自动化和智能化程度偏低等难题。转化形成的 《模锻件数字化车间生产过程控制系统功能要求》、《模锻件锻造过程信息追溯通用要求》、《汽车复杂锻件余热利用技术规范》 等系列湖北省地方标准，固化了锻造领域智能化生产的技术路线，推动智能技术改造传统制造业、推动工业化和信息化的深度融合，进一步促进我省汽车零部件锻造领域走可持续发展和绿色发展的道路。'
                },{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'以标准打破国外垄断保护民族产业',
                    content:'为了增强我国光电子产业原材料的话语权，利用创新基地搭建的TC/SC工作站，为企业与标准化委员会提供在线的标准供需对接服务，协助晶星科技公司开展二氯二氢硅和四氯化硅技术成果转化为技术标准工作。转化形成的 《电子工业用二氯二氢硅》 和《电子工业用四氯化硅》 两项国家标准，填补了我国缺乏半导体原材料生产工艺和分析检测技术的空白，改变了以往国产产品因缺标准无法直接销售，都采用“先出口再进口的曲线运作模式”，解决了我国半导体和集成电路行业受制约的局面。该标准将改变目前先出口再进口的曲线模式，预计每年能为我国节省进口二氯二氢硅总额约1亿元，节省进口四氯化硅总额8.5亿元。'
                },{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'以标准规范 湖北省光伏产业发展',
                    content:'为了落实国家能源局发布的 《关于可再生能源发展“十三五规划实施的指导意见”》，促进湖北省光伏产业的合作与发展，推动建设完整的产业链和健康、成熟的市场，提升产业的整体竞争力，创新基地积极开展产学研协作的创新合作模式，联合全国 28 家光伏产业上下游机构成立了湖北省光伏产业标准联盟，发布实施一项团体标准 《湖北省光伏扶贫电站建设验收规范》，填补了我省在光伏扶贫电站建设验收领域的空白，对规范我省光伏电站特别是扶贫电站的建设提供了参考。'
                }],
                6:[{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'全国物流标准化城市',
                    content:'2016年开始，协助黄石市商务委员会、质监局、财政局完善国家试点项目整体方案，参与黄石市三单位组织的试点企业2轮立项评审与结项评审工作。 2017年，协助试点企业申报立项、研制5项省级物流地方标准，涉及城市配送、鞋服仓储、医药仓储、公路运输、玻璃容器包装储运领域。承担《黄石市商贸物流标准体系研究》政采项目，汇总现行国标、行标与新研制的企标共计300余项。协助湖北浙联鞋服公司、湖北众联物流公司完成仓库功能分区、划线与施工工作；指导劲酒公司、劲鹏制盖公司、浙联鞋服公司按照包装标准模数改善产品外包装盒。 通过物流标准化的宣贯与持续实施改进，黄石市经济与社会效益显著，综合物流成本下降3.91%，平均货损率降低0.15%，物流装卸效率平均提高2.02倍，车辆周转效率提高2.39倍以上，装卸搬运单位成本下降45%'
                },{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'以“互联网+放管服”',
                    content:'2017 年以来，省标质院抓紧落实省政府关于“互联网 + 放管服”改革的有关部署要求，精心组织，深入研究，扎实推进行政审批标准化工作，为实现全省政务服务“一张网”提供标准化技术支撑。研制发布 《行政许可事项编码规则》、《行政许可事项服务指南编写规范》、《行政许可事项审查细则编写规范》 等三项基础标准为先导，相继确定了十大类行政权力和公共服务事项标准化要素，统一了全省网上政务服务标准化工作范式。配合省编办基本完成省市县乡四级清单体系搭建，指导各地各部门编制录入服务指南和审查细则，配合省政府开展政务服务“一张网”建设进度情况月度考核通报。联合国家行政审批标准化工作组共同研制省级地方标准 《行政许可事项网上办理质效评价规范》，助力省市县乡村五级政务服务标准化全覆盖。作为全省“数字政府”建设顶层设计专班成员，为“数字政府”建设提供标准化技术支撑'
                },{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'以标准促进传统行业转型升级',
                    content:'为促进传统锻造耗能产业转向全球产业链条中的高附加值产业，解决湖北三环锻造有限公司在标准研制和标准实施过程中遇到的难题，多次组织相关专家深入企业，通过标准化公共服务平台和基地标准供需服务的线上服务平台，成功解决锻造企业产能过剩、同质化竞争严重、自动化和智能化程度偏低等难题。转化形成的 《模锻件数字化车间生产过程控制系统功能要求》、《模锻件锻造过程信息追溯通用要求》、《汽车复杂锻件余热利用技术规范》 等系列湖北省地方标准，固化了锻造领域智能化生产的技术路线，推动智能技术改造传统制造业、推动工业化和信息化的深度融合，进一步促进我省汽车零部件锻造领域走可持续发展和绿色发展的道路。'
                },{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'以标准打破国外垄断保护民族产业',
                    content:'为了增强我国光电子产业原材料的话语权，利用创新基地搭建的TC/SC工作站，为企业与标准化委员会提供在线的标准供需对接服务，协助晶星科技公司开展二氯二氢硅和四氯化硅技术成果转化为技术标准工作。转化形成的 《电子工业用二氯二氢硅》 和《电子工业用四氯化硅》 两项国家标准，填补了我国缺乏半导体原材料生产工艺和分析检测技术的空白，改变了以往国产产品因缺标准无法直接销售，都采用“先出口再进口的曲线运作模式”，解决了我国半导体和集成电路行业受制约的局面。该标准将改变目前先出口再进口的曲线模式，预计每年能为我国节省进口二氯二氢硅总额约1亿元，节省进口四氯化硅总额8.5亿元。'
                },{
                    src:require('@/assets/image/content/page2_1.png'),
                    title:'以标准规范 湖北省光伏产业发展',
                    content:'为了落实国家能源局发布的 《关于可再生能源发展“十三五规划实施的指导意见”》，促进湖北省光伏产业的合作与发展，推动建设完整的产业链和健康、成熟的市场，提升产业的整体竞争力，创新基地积极开展产学研协作的创新合作模式，联合全国 28 家光伏产业上下游机构成立了湖北省光伏产业标准联盟，发布实施一项团体标准 《湖北省光伏扶贫电站建设验收规范》，填补了我省在光伏扶贫电站建设验收领域的空白，对规范我省光伏电站特别是扶贫电站的建设提供了参考。'
                }]
            },
            visible: false,
            visibleData:{}
        }
    },
    created(){
        this.getShowAll()
    },
    methods:{
        getShowAll(){
            getShowAll({visible_EQ:1,visible2_EQ:1}).then(res => {
                this.page1Data = res.map((item, i) => {
                    item.list = []
                    this.getNewsList(item.id, item, i)
                    return item
                })
            })
        },
        getNewsList(id, obj, i){
            getNewsList(id).then(res => {
                obj.list = res.data
                obj.list[0].imgSrc = getImgRegex(res.data[0].content)
            })
        },
        page1Open(item, t){
            // 更多
            if(t === 1){
                // 建党
                if(item.id === '7F170C29B59B7E01E0532D0B000A8E43'){
                    window.open(`${window.location.origin}/#/PartyArticleListPage`, '_blank');
                }else{
                    // 文章列表
                    window.open(`${window.location.origin}/#/ArticleListPage/${item.id}`, '_blank');
                }
            }else{
                // 文章详情
                window.open(`${window.location.origin}/#/ArticleInfo/${item.id}`, '_blank');
            }
        },
        page2Open(i){
            let index = i+1
            window.open(`${window.location.origin}/#/Association/${index}`, '_blank');
        },
        page2Style(i){
            let style = {
                top: '0px',
                left:'0px',
                zIndex: 9
            }
            switch (i) {
                case 1:
                    style = {
                        top:'0px',
                        left:'390px',
                        zIndex: 9
                    }
                    break;
                case 2:
                    style = {
                        top:'0px',
                        left:'780px',
                        zIndex: 9
                    }
                    break;
                case 3:
                    style = {
                        top: '210px',
                        left:'0px',
                        zIndex: 9
                    }
                    break;
                case 4:
                    style = {
                        top: '210px',
                        left:'390px',
                        zIndex: 9
                    }
                    break;
                case 5:
                    style = {
                        top: '210px',
                        left:'780px',
                        zIndex: 9
                    }
                    break;
                case 6:
                    style = {
                        top: '420px',
                        left:'0px',
                        zIndex: 9
                    }
                    break;
                case 7:
                    style = {
                        top: '420px',
                        left:'390px',
                        zIndex: 9
                    }
                    break;
                case 8:
                    style = {
                        top: '420px',
                        left:'780px',
                        zIndex: 9
                    }
                    break;
            
                default:
                    break;
            }
            return style
        },
        page2Card(i){
            this.page2CardIndex = i
        },
        page3Style(i){
            let style = {
                top: '0px',
                left:'0px',
                zIndex: 9
            }
            switch (i) {
                case 1:
                    style = {
                        top: '0px',
                        left:'390px',
                        zIndex: 9
                    }
                    break;
                case 2:
                    style = {
                        top: '0px',
                        left:'780px',
                        zIndex: 9
                    }
                    break;
                case 3:
                    style = {
                        top: '150px',
                        left:'0px',
                        zIndex: 9
                    }
                    break;
                case 4:
                    style = {
                        top: '150px',
                        left:'390px',
                        zIndex: 9
                    }
                    break;
                case 5:
                    style = {
                        top: '150px',
                        left:'780px',
                        zIndex: 9
                    }
                    break;
            
                default:
                    break;
            }
            return style
        },
        page3Card(i){
            this.page3CardIndex = i
        },
        modalVisible(item){
            this.visible = true
            this.visibleData = item
        }
    }
};
</script>

<style lang="less" scoped>
.content{
    background-color: #fff;
    .carousel_view{
        width: 100%;
        position: relative;
        width: 100%;
        vertical-align: top;
        background-size: cover;
        background-position: top;
        height: 800px;
        img{
            width: 100%;
            vertical-align: top;
            background-size: cover;
            background-position: top;
            height: 800px;
        }
        .carousel_view_img{
            width: 100%;
            vertical-align: top;
            background-size: cover;
            background-position: top;
            height: 800px;
        }
        .slogan{
            line-height: 1;
            position: absolute;
            bottom: 160px;
            left: calc(50% - 530px);
            z-index: 1;
            padding: 15px 20px;
            background:linear-gradient(to left top, transparent 50%, rgba(0, 0, 0, .3) 0, rgba(0, 0, 0, .2)) no-repeat 100% 100%/2em 2em, linear-gradient(-45deg, transparent 1.41em, #909 0);
            >*{
                color:#fff;
            }
            h1{
                font-size: 34px;
                color: #fff;
                margin-bottom: 15px !important;
            }
            h2{
                display: flex;
                align-items: center;
                font-size: 19px;
            }
            i{
                margin-left: 10px;
            }
        }
        .slogan2{
            padding-right: 60px;
            background: linear-gradient(to left top, transparent 50%, rgba(0, 0, 0, .3) 0, rgba(0, 0, 0, .2)) no-repeat 100% 100%/2em 2em, linear-gradient(-45deg, transparent 1.41em, #14c6b3 0);
            h3{
                font-size: 16px;
            }
        }
    }
    .content_page1{
        width: 1200px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: 69px auto 66px;
        .content_page1_articleTab{
            width: 340px;
            .page1_articleTab_title{
                position: relative;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding-bottom: 10px;
                border-bottom: 3px solid #06c;
                margin-bottom: 30px;
                >div{
                    width: 135px;
                    font-size: 24px;
                    color: #06c;
                    text-align: justify;
                    text-align-last: justify;
                    padding:0 30px;
                }
                .ant-btn{
                    border: 1px solid #777;
                    border-radius: 100px;
                    background: transparent;
                    color: #777;
                    padding: 9px 20px;
                    line-height: 1; 
                }
                .ant-btn:hover {
                    text-decoration: none;
                }
            }
            // .page1_articleTab_title:after{
            //     content: "";
            //     position: absolute;
            //     left: 0;
            //     bottom: -4px;
            //     width: 50%;
            //     height: 2px;
            //     background-color: #06c;
            // }
            .page1_articleTab_content{
                .a_img{
                    width: 100%;
                    height: 180px;
                    img{
                        width: 100%;
                        height: 180px;
                    }
                }
                h6{
                    font-size: 12px;
                    color: #666;
                    margin: 30px 0 12px;
                }
                a{
                    font-size: 14px;
                    line-height: 26px;
                    width: 340px;
                    height: 26px;
                    color: #666;
                    display: block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                a:hover {
                    color: #094fc0;
                }
                .a_h2{
                    margin-bottom: 30px;
                    h2{
                        font-size: 20px;
                        color: #333;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-break: break-all;
                        white-space: nowrap;
                    }
                }
                
                .top1:hover{
                    .a_h2>h2{
                        color: #094fc0;
                    }
                }
            }
        }
        .content_page1_articleTab:nth-child(2){
            .page1_articleTab_title{
                border-color: #096;
                >div{
                    color: #096;
                }
            }
            .page1_articleTab_title:after{
                background-color: #096;
            }
        }
        .content_page1_articleTab:nth-child(3){
            .page1_articleTab_title{
                border-color: #c03;
                >div{
                    color: #c03;
                }
            }
            .page1_articleTab_title:after{
                background-color: #c03;
            }
        }
    }
    .page2{
        padding: 70px calc(50% - 580px) 90px;
        border-top: 1px solid #ccc;
        >h1{
            font-size: 36px;
            line-height: 28px;
            letter-spacing: 0;
            color: #06c;
            width: 280px;
            border-bottom: 1px solid #06c;
            margin: 0 auto 18px;
            padding: 0 20px 18px;
            text-align: justify;
            text-align-last: justify;
        }
        >p{
            text-align: center;
            width: 600px;
            margin: 0 auto 32px;
        }
        .page2_view{
            height: 620px;
            position: relative;
            .page2_view_card{
                height: 200px;
                position: absolute;
                width: 380px;
                top: 0px;
                left: 0px;
                z-index: 9;
                background-color: #000;
                line-height: 1;
                transition: all .3s;
                transform: translateZ(0);
                overflow: hidden;
                >span{
                    cursor: pointer;
                    >div{
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        background-size: cover;
                        background-position: 50%;
                    }
                    >h1{
                        font-size: 20px;
                        color: #fff;
                        margin: 0;
                        position: absolute;
                        top: 50%;
                        left: 50px;
                        transform: translateY(-50%);
                        transition: all .3s;
                    }
                    >p{
                        font-size: 14px;
                        color: #fff;
                        position: absolute;
                        left: 50px;
                        top: 80px;
                        max-width: 800px;
                        line-height: 22px;
                        opacity: 0;
                        white-space: pre-line;
                    }
                    .ImgOpen{
                        position: absolute;
                        bottom: 15px;
                        left: 45px;
                        display: none;
                    }
                }
                .ImgClose{
                    position: absolute;
                    left: 50px;
                    bottom: 20px;
                    z-index: 100;
                }
                .closeBtn{
                    display: none;
                    top: 50px;
                    right: 40px;
                    position: absolute;
                    cursor: pointer;
                    align-items: center;
                    >span{
                        margin-right: 15px;
                        opacity: 0;
                        transform: translateX(100%);
                        transition: all .3s;
                        color: #fff;
                        font-size: 18px;
                    }
                    >img{
                        transition: all .8s;
                    }
                }
                .closeBtn:hover span {
                    transform: none;
                    opacity: 1;
                }
                .closeBtn:hover  img {
                    transform: rotate(1turn);
                }
                .ChildrenFunctionContainer{
                    position: absolute;
                    bottom: 50px;
                    left: 49px;
                    right: 49px;
                    opacity: 0;
                    pointer-events: none;
                    transition: none;
                    >h2{
                        font-size: 24px;
                        color: #fff;
                        border-bottom: 1px solid #fff;
                        padding-bottom: 20px;
                        margin-bottom: 20px;
                    }
                    >div{
                        display: flex;
                        cursor: pointer;
                        >div{
                            color: #fff;
                            opacity: .85;
                            text-align: center;
                            width: calc(33.33333% - 6.66667px);
                            margin-right: 10px;
                            >img{
                                margin-bottom: 10px;
                                border: 1px solid #cdcdcd;
                                transition: all .3s;
                            }
                            >img:hover{
                                box-shadow: 0 0 15px hsla(0, 0%, 100%, .7);
                            }
                            >span{
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
            .page2_view_card:hover{
                >span{
                    .ImgOpen{
                        display: unset;
                    }
                }
                .ImgClose{
                    display: none;
                }
            }
            .page2_view_card.page2_view_card_Open{
                top: 0 !important;
                left: 0 !important;
                right: 0 !important;
                height: 100% !important;
                width: 100% !important;
                z-index: 10 !important;
                >span{
                    >h1{
                        font-size: 32px;
                        left: 50px;
                        top: 50px;
                    }
                    >p{
                        left: 50px;
                        right: unset;
                        text-align: left;
                        opacity: 1;
                        transition: all 1s;
                    }
                    .ImgOpen{
                        display: none;
                    }
                }
                .ImgClose{
                    display: none;
                }
                .closeBtn{
                    display: inline-flex;
                }
                .ChildrenFunctionContainer{
                    transition: all 1s;
                    opacity: 1;
                    pointer-events: all;
                    >div>div:nth-child(1){
                        animation: antd-pro-pages-index-work-and-server-move 1s 1;
                    }
                    >div>div:nth-child(2){
                        animation: antd-pro-pages-index-work-and-server-move 1s .5s 1;
                    }
                    >div>div:nth-child(3){
                        animation: antd-pro-pages-index-work-and-server-move 1s 1s 1;
                    }
                    >div>div{
                        transform: translateX(100%);
                        animation-fill-mode: forwards !important;
                        transition: box-shadow .3s;
                    }
                }
            }
        }
    }
    .page3{
        padding: 70px calc(50% - 580px) 90px;
        border-top: 1px solid #ccc;
        >h1{
            font-size: 36px;
            line-height: 28px;
            letter-spacing: 0;
            color: #06c;
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content;
            border-bottom: 1px solid #06c;
            margin: 0 auto 18px;
            padding: 0 20px 18px;
            text-align: justify;
            text-align-last: justify;
        }
        >p{
            text-align: center;
            width: 600px;
            margin: 0 auto 32px;
        }
        .page2_view{
            height: 290px;
            position: relative;
            >div{
                height: 140px;
                position: absolute;
                width: 380px;
                top: 0px;
                left: 0px;
                z-index: 9;
                background-color: #000;
                line-height: 1;
                transition: all .3s;
                transform: translateZ(0);
                overflow: hidden;
                >div{
                    cursor: pointer;
                    .divItemBg{
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                    }
                    .header{
                        position: absolute;
                        display: inline-flex;
                        justify-content: space-between;
                        align-items: center;
                        top: 50%;
                        left: 50px;
                        right: 50px;
                        transform: translateY(-50%);
                        >h1{
                            font-size: 20px;
                            color: #fff;
                            margin: 0;
                            >p{
                                font-size: 14px;
                                color: #fff;
                                max-width: 800px;
                                line-height: 22px;
                                opacity: 0;
                                display: none;
                            }
                        }
                    }
                    .closeBtn{
                        display: none;
                        top: 50px;
                        right: 40px;
                        position: absolute;
                        cursor: pointer;
                        align-items: center;
                        >span{
                            margin-right: 15px;
                            opacity: 0;
                            transform: translateX(100%);
                            transition: all .3s;
                            color: #fff;
                            font-size: 18px;
                        }
                        >img{
                            transition: all .8s;
                        }
                    }
                    .closeBtn:hover span {
                        transform: none;
                        opacity: 1;
                    }
                    .closeBtn:hover  img {
                        transform: rotate(1turn);
                    }
                    .ChildrenFunctionContainer{
                        position: absolute;
                        top: 150px;
                        left: 50px;
                        display: flex;
                        opacity: 0;
                        pointer-events: none;
                        transition: none;
                        >div{
                            width: 204px;
                            margin-right: 10px;
                            color: #fff;
                            position: relative;
                            opacity: .85;
                            padding: 20px;
                            >h1{
                                color: #fff;
                                font-size: 30px;
                            }
                            .span{
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
        .page2_view .page3_view_card_Open{
            top: 0 !important;
            left: 0 !important;
            right: 0 !important;
            height: 100% !important;
            width: 100% !important;
            z-index: 10 !important;
            >div{
                .divItemBg{
                    background-color: #000 !important;
                    transform: scale(1.2);
                    animation: antd-pro-pages-index-quick-function-floatVer 5s infinite;
                }
                .header{
                    position: relative;
                    left: 50px;
                    right: unset;
                    top: 30px;
                    bottom: 0;
                    transform: none;
                    >img{
                        margin-right: 30px;
                    }
                    >h1{
                        font-size: 32px;
                        >p{
                            display: unset;
                            opacity: 1;
                        }
                    }
                }
                .closeBtn{
                    display: inline-flex;
                }
                .ChildrenFunctionContainer{
                    transition: all 1s;
                    opacity: 1;
                    pointer-events: all;
                    >div:nth-child(1){
                        animation: antd-pro-pages-index-work-and-server-move 1s 1;
                    }
                    >div:nth-child(2){
                        animation: antd-pro-pages-index-work-and-server-move 1s .5s 1;
                    }
                    >div:nth-child(3){
                        animation: antd-pro-pages-index-work-and-server-move 1s 1s 1;
                    }
                    >div:nth-child(4){
                        animation: antd-pro-pages-index-work-and-server-move 1s 1.5s 1;
                    }
                    >div:nth-child(5){
                        animation: antd-pro-pages-index-work-and-server-move 1s 2s 1;
                    }
                    >div{
                        transform: translateX(100%);
                        opacity: 0;
                        animation-fill-mode: forwards !important;
                        transition: box-shadow .3s;
                    }
                }
            }
        }
        .carousel{
            margin-top: 30px;
            .ant-carousel{
                .slick-slide {
                    text-align: center;
                    height: 150px;
                    width: 186px;
                    line-height: 72px;
                    overflow: hidden;
                }
                .custom-slick-arrow {
                    width: 32px;
                    height: 32px;
                    font-size: 35px;
                    color: #ccc;
                    background-color: rgba(31, 45, 61, 0.11);
                }
                .custom-slick-arrow:first-child {
                    left: -30px;
                }
                .custom-slick-arrow:last-child {
                    right: -30px;
                }
                .custom-slick-arrow:before {
                    display: none;
                }
                .custom-slick-arrow:hover {
                    opacity: 0.5;
                }
                .slick-slide h3 {
                    color: #fff;
                }
                .carousel_view{
                    width: 387px;
                    cursor: pointer;
                    >img{
                        padding: 0 10px;
                        height: 180px;
                    }
                    >span{
                        font-size: 18px;
                        color: #666;
                        display: block;
                        text-align: center;
                        margin-top: 20px;
                    }
            }
            }
            
        }
    }
}
@keyframes antd-pro-pages-index-work-and-server-move {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
        opacity: .85;
    }
}
@keyframes antd-pro-pages-index-quick-function-floatVer {
    0% {
        left: -30px;
        right: 0;
    }
    50% {
        left: 0;
        right: -30px;
    }
    100% {
        left: -30px;
        right: -0px;
    }
}
</style>
  