<template>
    <a-layout class="app_layout">
      <a-layout-header :class="$route.fullPath == '/PartyArticleListPage'?'partyArticleListPage':''">
        <!-- 导航栏组件 -->
        <Navigation class="container" />
      </a-layout-header>
      <a-layout-content>
        <!-- 网站内容 -->
        <router-view></router-view>
      </a-layout-content>
      <a-layout-footer class="contact_page">
        <!-- 页脚组件 -->
        <Contact />
        <Footer />
      </a-layout-footer>
    </a-layout>
  </template>
  
  <script>
  import Navigation from '../components/Navigation.vue';
  import Contact from '../components/contact.vue';
  import Footer from '../components/Footer.vue';
  import { get100 } from "../api/index";
  
  export default {
    components: {
      Navigation,
      Contact,
      Footer,
    },
    created(){
      this.get100()
    },
    methods:{
      get100(){
        get100({module_EQ:0}).then(res => {
          console.log(res);
        })
      }
    }
  };
  </script>
  
  <style lang="less" scoped>
  .container{
    max-width: 1200px;
    color: #fff;
    margin: 0 auto;
    height: 90px;
    line-height: 90px;
    display: flex;
    align-items: center;
    font-size: 16px;
  }
  .app_layout{
    
    background-color: #fff;
    .ant-layout-header{
      background: linear-gradient(0, transparent, rgba(0, 0, 0, .85));
      z-index: 100 !important;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 90px;
      line-height: 90px;
    }
    .ant-layout-content{
      background-color: #fff;
    }
    .ant-layout-header.partyArticleListPage{
      background: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(204, 0, 0, 0.85));
    }
    .contact_page{
      padding: 0;
      background-color: #fff;
    }
  }
  </style>
  