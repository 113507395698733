<template>
    <div class="articleInfo">
        <a-spin tip="Loading..." :spinning="spinning">
            <div class="page_div">
                <h1>{{ pageObj.title }}</h1>
              <h5 style="align-content: center;text-align: left;">发布时间：{{pageObj.createTime}}</h5>
              <h1 class="h1border"></h1>
                <div v-html="pageObj.content"></div>
                
                <!-- <a v-if="pageObj.hasAddOn"  class="fileicon" :href="pageObj.addOn" >附件下载</a> -->
                
                <div v-if="pageObj.hasAddOn" style="margin-left: 30px;margin-top: 0px;margin-bottom: 16px;">
<!--                    <span style="font-size: 18px;">附件：</span>-->
                    <a v-for="(item, i) in pageObj.addOns" :class="'fileicon fileicon-'+item.suffix" :href="item.path" >{{i+1}}.{{ item.showName }}</a>
                </div>
                
            </div>
        </a-spin>
    </div>
</template>

<script>
import { getNews } from "@/api/ArticleListPage";
import { BaseURL } from "@/utils/config";
export default {
    data() {
        return {
            spinning: false,
            id:'',
            pageObj:{
                title: '',
                content:''
            }
        };
    },
    created(){
        this.id = this.$route.params.id?this.$route.params.id:''
        this.getNews()
    },
    methods:{
        getNews(){
            this.spinning = true
            getNews(this.id).then(res => {
                this.spinning = false
                res.content = res.content.replace(/\/\plugins\/\ueditor/g, `${BaseURL}/plugins/ueditor`)
                this.pageObj = res
                if(res.addOn!=''&&res.addOn!=null&&res.addOn!=undefined) {
                  this.pageObj.hasAddOn = true
                  if(this.pageObj.addOn.startsWith("[")){
                    // this.pageObj.addOn = BaseURL + this.pageObj.addOn
                    this.pageObj.addOns = JSON.parse(this.pageObj.addOn)
                    for (let index = 0; index < this.pageObj.addOns.length; index++) {
                      this.pageObj.addOns[index].path = BaseURL + this.pageObj.addOns[index].path
                      this.pageObj.addOns[index].suffix = this.pageObj.addOns[index].path.slice(this.pageObj.addOns[index].path.lastIndexOf('.') + 1);
                    }
                    if (this.pageObj.addOns.length <1){
                      this.pageObj.hasAddOn = false
                    }
                  }else {
                    try{
                      this.pageObj.addOns = []
                      const suffix = this.pageObj.addOn.slice(this.pageObj.addOn.lastIndexOf('.') + 1);
                      this.pageObj.addOns.push({path:BaseURL + this.pageObj.addOn, showName:'附件一.'+suffix, suffix:suffix})
                    }catch (e) {
                      console.log(e)
                    }
                  }


                    
                } else {
                    this.pageObj.hasAddOn = false
                }
                
            })
        }
    }
}
</script>

<style lang="less" scoped>
.h1border{
  font-size: 30px;
  color: #06c;
  border-bottom: 1px solid #06c;
  padding-bottom: 15px;
  margin-bottom: 62px;
  line-height: 1.5;
}
.articleInfo{
    padding-left: calc(50% - 600px);
    padding-right: calc(50% - 600px);
    background-color: #fff;
    margin-top: 90px;
    .page_div{
        padding: 0px 0;
        >h1{
            font-size: 30px;
            color: #06c;
            //border-bottom: 1px solid #06c;
            //padding-bottom: 15px;
            //margin-bottom: 62px;
            //line-height: 1.5;
        }
        .fileicon {
            margin-left: 30px;
            display: block;
            cursor: pointer;
            overflow: hidden;
            margin-top: 8px;
            width: 100%;
            height: 25px;
            background-image: url(@/assets/image/filepin.png);
            background-repeat: no-repeat;
            background-size: 20px 20px;
            padding-left: 25px;
            font-size: 1.2em;
        }
      .fileicon- {
        display: block;
        cursor: pointer;
        overflow: hidden;
        margin-top: 8px;
        width: 100%;
        height: 25px;
        background-image: url(@/assets/image/filepin.png);
        background-repeat: no-repeat;
        background-size: 20px 20px;
        padding-left: 25px;
        font-size: 1.2em;
      }
        .fileicon-zip {
            display: block;
            cursor: pointer;
            overflow: hidden;
            margin-top: 8px;
            width: 100%;
            height: 25px;
            background-image: url(@/assets/image/file-zip.png);
            background-repeat: no-repeat;
            background-size: 20px 20px;
            padding-left: 25px;
            font-size: 1.2em;
        }
        .fileicon-pdf {
            display: block;
            cursor: pointer;
            overflow: hidden;
            margin-top: 8px;
            width: 100%;
            height: 25px;
            background-image: url(@/assets/image/file-pdf.png);
            background-repeat: no-repeat;
            background-size: 20px 20px;
            padding-left: 25px;
            font-size: 1.2em;
        }
    }
}    
</style>