<template>
    <div class="unordered">
        <a-drawer
            placement="top"
            height="calc(100vh)"
            :closable="false"
            :visible="visible"
            >
            <div class="unordered_content">
                <div class="closeBtn" @click="onClose">
                    <span>关闭</span>
                    <img
                        slot="cover"
                        alt="example"
                        src="@/assets/image/close.png"
                    />
                </div>
                <h1>服务机构&研究所</h1>
                <a-row type="flex">
                    <a-col :span="12" class="borderR" @click="open(item)" v-for="(item, i) in dataList" :key="i">
                        <div class="col_div">
                            <div class="left">
                                <img
                                    slot="cover"
                                    alt="example"
                                    :src="item.src"
                                />
                            </div>
                            <div class="right">
                                <h2>{{ item.title }}</h2>
                                <p>{{ item.content }}</p>
                            </div>
                        </div>
                    </a-col>
                </a-row>
                <a-divider class="divider">旗下站点</a-divider>
                <a-carousel arrows :dots="false" autoplay :slides-to-show="4" :slides-to-scroll="1">
                    <div
                        slot="prevArrow"
                        slot-scope="props"
                        class="custom-slick-arrow"
                        style="left: 10px;zIndex: 1"
                        >
                        <a-icon type="left" />
                    </div>
                    <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 10px">
                        <a-icon type="right" />
                    </div>
                    <div class="carousel_view" v-for="(item, i) in imgData" :key="i">
                        <a :href="item.href ? item.href : 'javascript: void(0)'" :target="item.href ? '_blank' : ''">
                            <img
                            v-if="item.href"
                            slot="cover"
                            alt="example"
                            :src = "hovered === i ? item.hoverimg : item.img"
                            @mouseover="hovered = i"
                            @mouseout="hovered = null"
                            />
                            <img
                            v-else
                            slot="cover"
                            alt="example"
                            :src="item.img"
                            />
                        </a>
                        
                    </div>
                </a-carousel>
            </div>
        </a-drawer>
    </div>
</template>
<script>
import { get10 } from "@/api/index";
export default {
    name:'Unordered',
    data () {
        return {
            hovered: null,
            visible: false,
            dataList:[
                {
                    id: 1,
                    src: require('@/assets/image/dr.png'),
                    title: '事业发展规划部',
                    content: '省标质院事业发展规划部（以下简称“事业部”）是负责、协调全院业务工作开展、管理收入类经济合同工作开展的业务部门。负责市场开发、拓展、各业务部门商务信息收集整理、经济类收入合同的日常管理，合同登记'
                },{
                    id: 2,
                    src: require('@/assets/image/sl.png'),
                    title: '标准文献馆',
                    content: '负责全省标准文献资源的规划与建设，提供安全生产监管、国土资源管理、农林等领域标准化技术咨询服务。'
                },{
                    id: 3,
                    src: require('@/assets/image/ic.png'),
                    title: '物品编码部',
                    content: '从事物品编码管理、技术研究与成员服务的专职部门，承担湖北省地理标志产品标准化技术委员会秘书处工作职责。'
                },{
                    id: 4,
                    src: require('@/assets/image/ti.png'),
                    title: '高新技术与产业标准化研究所',
                    content: '开展科技成果转化、团体标准运营、标准第三方评估、检测与标准验证一体化实验室孵化等专业领域的标准化科研与服务工作。'
                },{
                    id: 5,
                    src: require('@/assets/image/ti1.png'),
                    title: '现代服务业与公共管理标准化研究所',
                    content: '专业从事生活性服务业、生产性服务业和社会管理与公共服务等相关服务标准化顶层政策设计以及标准化成果的应用研究。'
                },{
                    id: 6,
                    src: require('@/assets/image/is.png'),
                    title: '国际标准化研究所',
                    content: '专业从事技术性贸易措施研究、WTO通报咨询、国际标准化合作与研究、检验检测研究和湖北省标准学会的专职部门。'
                },{
                    id: 7,
                    src: require('@/assets/image/is1.png'),
                    title: '质量发展与质量安全研究所',
                    content: '开展宏观质量监管、中观质量发展和微观质量提升的顶层政策设计、前沿理论研究、标准制修订和市场服务工作。'
                },{
                    id: 8,
                    src: require('@/assets/image/log.png'),
                    title: '现代物流标准化研究所',
                    content: '专业从事商贸、物流、交通行业标准化研究及技术服务，负责省物标委秘书处的日常工作运转。'
                },{
                    id: 9,
                    src: require('@/assets/image/is1.png'),
                    title: '资源与环境研究所',
                    content: '专业开展节能、节水、生态文明以及环保等领域标准化前沿理论、顶层政策设计以及标准化成果的应用研究。'
                }
            ],
            imgData:[
                {
                    img: '/static/数字标准馆.png',
                },
                {
                    img: '/static/HBGIP.png',
                },
                {
                    img: '/static/HBIT.png',
                },
                {
                    img: '/static/HBOSIT.png',
                },
                {
                    img: '/static/HBSCCDSP.png',
                },
                {
                    img: '/static/OVCTSI (2).png',
                },
                {
                    img: '/static/WechatIMG85.png',
                    href: 'http://whfs.tpddns.cn:8304/index',
                    hoverimg: '/static/WechatIMG85a.png'
                },
                {
                    img: '/static/dsjjcsys.png',
                    href: 'http://whfs.tpddns.cn:8302/index',
                    hoverimg: '/static/dsjjcsysa.png'
                },
                {
                    img: '/static/WechatIMG87.png',
                },
            ]
        }
    },
    created(){
    //   this.get10()
    },
    methods:{
        get10(){
            get10({visible_EQ:1,type_EQ:'enter',module_EQ:'index'}).then(res => {
                this.imgData = res.data.map(element => {
                    element.imgUrl = eval('('+element.imgUrl+')');
                    element.imgSrc = `/static/${element.imgUrl[0].name}`
                    return element
                });
            })
        },
        onClose(){
            this.visible = false
        },
        open(item){
            window.open(`${window.location.origin}/#/Association/${item.id}`, '_blank');
        }
    }
};
</script>
<style lang="less">
    .unordered_content{
        padding-top: 60px;
        padding-left: calc(50% - 580px);
        padding-right: calc(50% - 580px);
        h1{
            font-size: 20px;
            line-height: 43px;
            color: #333;
            margin-bottom: 20px;
            text-align: center;
            margin-top: 30px;
        }
        .borderR:nth-child(2n-1){
            border-right: 1px solid #333;
            .col_div{
                margin-right: 50px;
            }
        }
        .borderR:nth-child(2n){
            .col_div{
                margin:0 50px;
            }
        }
        .col_div{
            color: #999;
            display: inline-flex;
            align-content: flex-start;
            align-items: flex-start;
            justify-content: center;
            cursor: pointer;
            transition: all .3s;
            .left{
                margin-right: 40px;
            }
            .right{
                h2 {
                    font-size: 20px;
                    letter-spacing: 1px;
                    color: #fff;
                }

            }
        }
        .col_div:hover {
            opacity: .5;
        }
        .divider::before,.divider::after{
            border-color: #333;
        }
        .divider{
            font-size: 20px;
            color: #333;
        }
        .ant-carousel{
            .slick-slide {
                text-align: center;
                height: 150px;
                width: 186px;
                line-height: 72px;
                overflow: hidden;
            }
            .custom-slick-arrow {
                width: 25px;
                height: 25px;
                font-size: 25px;
                color: #fff;
                background-color: rgba(31, 45, 61, 0.11);
                opacity: 0.3;
            }
            .custom-slick-arrow:first-child {
                left: -30px;
            }
            .custom-slick-arrow:last-child {
                right: -30px;
            }
            .custom-slick-arrow:before {
                display: none;
            }
            .custom-slick-arrow:hover {
                opacity: 0.5;
            }
            .slick-slide h3 {
                color: #fff;
            }
            .carousel_view{
                display: flex !important;
                justify-content: center;
                align-items: center;
                height: 150px;
                img{
                    cursor: pointer;
                }
            }
        }
    }
</style>
