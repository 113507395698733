<template>
    <div class="AboutUs">
        <div class="AboutUs_l">
            <h1>机构概况</h1>
            <ul class="AboutUs_l_ul">
                <li v-for="(item, i) in listData" :key="i" :class="activeIndex == i?'active':''" @click="liClick(i)">· {{ item }}</li>
            </ul>
        </div>
        <div class="AboutUs_r">
            <div class="k1" v-show="activeIndex == 0">
                <h1>湖北省标准化与质量研究院 ——湖北省标准化信息服务的技术支撑</h1>
                <div>
                    <img 
                        slot="cover"
                        alt="example"
                        :src="require('@/assets/image/AboutUs/history.png')"
                    />
                </div>
            </div>
            <div class="k2"  v-show="activeIndex == 1">
                <h1>领导介绍</h1>
                <div>
                    <div v-for="(item, i) in k2Data" :key="i">
                        <h2>{{ item.h2 }}</h2>
                        <h3 v-html="item.h3"></h3>
                        <img 
                            slot="cover"
                            alt="example"
                            :src="item.src"
                        />
                    </div>
                </div>
            </div>
            <div class="k3" v-show="activeIndex == 2">
                <h1>获奖荣誉</h1>
                <div>
                    <div v-for="(item, i) in k3Data" :key="i">
                        <h2>{{ item.h2 }}</h2>
                        <img
                            v-for="(item1, i1) in item.imgList" :key="i1"
                            slot="cover"
                            alt="example"
                            :src="item1"
                        />
                    </div>
                </div>
            </div>
            <div class="k4" v-show="activeIndex == 3">
                <h1>机构文化</h1>
                <div>
                    我们的战略：让创新成为常态、让卓越成为习惯、成就机构百年品牌；
                    我们的使命：求规探矩，惟使方圆有序、天地和谐；
                    我们的愿景：当你需要最佳的秩序和更高的品质时，第一时间想到我们。
                    文化建设：做事是做人的载体，只有做有品质的事，才能做有品位的人；将复杂的技术问题留给自己，将简单的操作留给客户；客户的挑剔是我们走向成熟的基础等已成为团队共同认知的价值趋向。
                    体制机制：坚持以价值为导向的机构定位、以实绩为导向的制度设计、以成长为导向的激励机制；全员聘用、双向选择、竞争上岗、岗位目标量化管理已成为制度化保障下的常态；具有广泛群众基础的绩效考核委员会和学术委员会来监督绩效考核和学术评价的客观性和公正性
        
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            listData:[
                '发展历程',
                '领导介绍',
                '获奖荣誉',
                '机构文化'
            ],
            activeIndex: 0,
            k2Data:[{
                h2: '陈炎明',
                h3: '湖北省标准化与质量研究院院长、党委书记<br>主持全面工作。',
                src: require('@/assets/image/AboutUs/陈炎明.png')
            },{
                h2: '吴斌',
                h3: '湖北省标准化与质量研究院副院长、党委委员、纪委书记<br>分管党委办公室、物品编码部（大数据检测实验室）、质量发展与质量安全研究所。',
                src: require('@/assets/image/AboutUs/吴斌.png')
            },{
                h2: '鲁曦',
                h3: '湖北省标准化与质量研究院副院长、党委委员<br>分管财务资产部、标准文献馆、资源环境与绿色发展标准化研究所、事业发展部，联系湖北光谷标准创新科技有限公司。',
                src: require('@/assets/image/AboutUs/鲁曦.png')
            },{
                h2: '丁凡',
                h3: '湖北省标准化与质量研究院副院长、党委委员<br>分管高新技术与产业标准化研究所、社会治理标准化研究所、现代服务业与政府管理标准化研究所、商贸流通与供应链标准化研究所。',
                src: require('@/assets/image/AboutUs/丁凡.png')
            },{
                h2: '吴焱',
                h3: '湖北省标准化与质量研究院总工程师、党委委员<br>分管办公室、国际标准化研究所、标准化理论与战略研究所。',
                src: require('@/assets/image/AboutUs/吴焱.jpg')
            }],
            k3Data:[{
                h2:'党建类',
                imgList:[
                    require('@/assets/image/AboutUs/a1.jpg'),
                    require('@/assets/image/AboutUs/a2.jpg'),
                    require('@/assets/image/AboutUs/a3.jpg'),
                    require('@/assets/image/AboutUs/a4.jpg'),
                    require('@/assets/image/AboutUs/a5.jpg'),
                    require('@/assets/image/AboutUs/a6.jpg'),
                    require('@/assets/image/AboutUs/a7.jpg'),
                    require('@/assets/image/AboutUs/a8.jpg'),
                    require('@/assets/image/AboutUs/a9.jpg'),
                ]
            },{
                h2:'业务类',
                imgList:[
                    require('@/assets/image/AboutUs/b1.jpg'),
                    require('@/assets/image/AboutUs/b2.jpg'),
                    require('@/assets/image/AboutUs/b3.jpg'),
                    require('@/assets/image/AboutUs/b4.jpg'),
                    require('@/assets/image/AboutUs/b5.jpg'),
                    require('@/assets/image/AboutUs/b6.jpg'),
                ]
            }]
        }
    },
    created(){
        const i = this.$route.params.id?Number(this.$route.params.id):1
        this.activeIndex = [i-1]
    },
    methods:{
        liClick(i) {
            this.activeIndex = i
        }
    }
}
</script>

<style lang="less" scoped>
.AboutUs{
    margin-top: 90px;
    padding: 50px calc(50% - 600px);
    display: flex;
    background-color: #fff;
    .AboutUs_l{
        width: 210px;
        margin-right: 30px;
        padding-right: 30px;
        padding-left: 25px;
        border-right: 1px solid #e5e5e5;
        >h1{
            font-size: 20px;
            color: #06c;
            position: relative;
            padding-left: 17px;
            line-height: 1;
            margin-bottom: 20px;
        }
        >h1:before {
            content: "";
            position: absolute;
            width: 2px;
            height: unset;
            background-color: #06c;
            top: 0;
            bottom: 0;
            left: 0;
        }
        .AboutUs_l_ul{
            list-style: none;
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #e5e5e5;
            padding-left: 20px;
            li{
                font-size: 16px;
                color: #666;
                position: relative;
                padding-left: 10px;
                line-height: 1;
                margin-bottom: 10px;
                display: inline-block;
                cursor: pointer;
            }
            .active{
                color: #06c;
            }
        }
    }
    .AboutUs_r{
        flex: 1 1;
        h1{
            border-bottom: 1px solid #06c;
            padding-bottom: 10px;
            margin-bottom: 15px;
            font-size: 30px;
            color: #06c;
        }
        >div{
            white-space: pre-line;
            img{
                max-width: 100%;
            }
        }
        .k2{
            >div>div{
                margin-bottom: 40px;
            }
            img{
                width: 100%;
            }
        }
        .k3{
            img{
                width: 30%;
                margin: 5px;
            }
        }
    }
}
</style>